<template>
  <b-modal v-model="modal" :title="title" size="lg" :no-enforce-focus="true" :no-close-on-backdrop="true">
    <loading
      :active.sync="loading"
      color="#556ee6"
      loader="dots"
      :can-cancel="false"
      :is-full-page="false">
    </loading>
    <div class="row">
      <div class="col-lg-12">
        <form @submit.prevent="saveParticipant()" autocomplete="off">
          <div class="row">
            <div class="col-sm-6">
              <div class="form-group">
                <label for="name">Nome</label>
                <input
                  id="name"
                  v-model="participant.name"
                  name="name"
                  type="text"
                  class="form-control"
                  :class="{
                    'is-invalid': submitted && $v.participant.name.$error
                  }"
                />
                <div
                  v-if="submitted && !$v.participant.name.required"
                  class="invalid-feedback"
                >
                  O campo nome é obrigatório.
                </div>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="form-group">
                <label for="email">E-mail</label>
                <input
                  id="email"
                  v-model="participant.email"
                  name="email"
                  type="text"
                  class="form-control"
                  :class="{
                    'is-invalid': submitted && $v.participant.email.$error
                  }"
                />
                <div
                  v-if="submitted && !$v.participant.email.required"
                  class="invalid-feedback"
                >
                  O campo email é obrigatório.
                </div>
              </div>
            </div>
            <div class="col-sm-6" v-if="setting.access_type === 1">
              <div class="form-group">
                <label for="password">Senha</label>
                <span v-if="!participant.id && setting.access_type === 1">
                  <input
                    id="password"
                    v-model="participant.password"
                    name="password"
                    type="text"
                    class="form-control"
                    :class="{
                      'is-invalid': submitted && $v.participant.password.$error
                    }"
                  />
                  <div
                    v-if="submitted && !$v.participant.password.required"
                    class="invalid-feedback"
                  >
                    O campo senha é obrigatório.
                  </div>
                </span>
                <span v-if="participant.id">
                  <input
                    id="password"
                    v-model="participant.password"
                    name="password"
                    type="text"
                    class="form-control"
                  />
                </span>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="form-group">
                <label for="phone">Telefone</label>
                <input
                  id="phone"
                  v-model="participant.phone"
                  name="phone"
                  type="text"
                  class="form-control"
                />
              </div>
            </div>
            <div class="col-sm-6">
              <div class="form-group">
                <label for="city">Cidade</label>
                <input
                  id="city"
                  v-model="participant.city"
                  name="city"
                  type="text"
                  class="form-control"
                />
              </div>
            </div>
            <div class="col-sm-6">
              <div class="form-group">
                <label for="district">Estado</label>
                <multiselect
                  v-model="selected"
                  :options="states" class="helo"
                  :max-height="180"
                  trackBy="value"
                  label="label"
                  placeholder="Selecione uma opção"
                >
                </multiselect>
              </div>
            </div>
          </div>
          <div class="row mt-1">
            <div class="col-sm-12">
              <h5>Permissões de sala restristas</h5>
              <div class="form-group">
                <multiselect
                  tag-placeholder="Selecione uma ou mais salas"
                  placeholder="Selecione uma ou mais salas"
                  v-model="participant.rooms"
                  :options="optionsRooms"
                  class="helo"
                  selectLabel="Click para selecionar"
                  selectedLabel="Selecionado"
                  deselectLabel="Click para remover"
                  label="name"
                  track-by="id"
                  :max-height="180"
                  :multiple="true"
                  open-direction="bottom"
                >
                </multiselect>
              </div>
            </div>
          </div>
          <div class="row" v-if="participant.sympla">
            <div class="col-sm-12">
              <span v-if="participant.sympla" class="badge badge-soft-success font-size-11 mb-1">Integração Sympla</span>
              <table class="table table-centered table-nowrap table-hover">
                <thead>
                  <tr>
                    <th>Nº Pedido</th>
                    <th>Cód. Ingresso</th>
                    <th>Ingresso</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{{ participant.sympla_order_id }}</td>
                    <td>{{ participant.sympla_ticket_number }}</td>
                    <td>
                      <span v-if="participant.sympla_ticket_number" class="badge badge-soft-primary font-size-11 mr-1">{{ participant.sympla_ticket_name }}</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </form>
      </div>
    </div>
    <template v-slot:modal-footer>
      <b-button variant="secondary" @click="close()">Cancelar</b-button>
      <button type="submit" class="btn btn-primary mr-1" @click="saveParticipant()">Salvar</button>
    </template>
  </b-modal>
</template>
<script>
// Import component
import Loading from 'vue-loading-overlay'
// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css'
import { mapActions, mapState } from 'vuex'
import { states } from './statesData'
import Multiselect from 'vue-multiselect'
import { required } from 'vuelidate/lib/validators'
import { errorMessage, successMessage } from '@/components/alertsMessages'
import roomService from '@/pages/rooms/services'

export default {
  components: {
    Loading,
    Multiselect
  },
  props: {
    idParticipant: {
      type: Number || String,
      default: null
    }
  },
  data () {
    return {
      title: 'Novo Participante',
      loading: true,
      selected: '',
      fullPage: true,
      submitted: false,
      states: states,
      rooms: [],
      optionsRooms: [],
      participant: {},
      modal: false
    }
  },
  validations () {
    if (this.participant.id) {
      return {
        participant: {
          name: { required },
          email: { required }
        }
      }
    } else {
      if (this.setting.access_type === 1) {
        return {
          participant: {
            name: { required },
            email: { required },
            password: { required }
          }
        }
      }
      return {
        participant: {
          name: { required },
          email: { required }
        }
      }
    }
  },
  methods: {
    ...mapActions('event', {
      GetSetting: 'GetSetting'
    }),
    ...mapActions('participant', {
      getParticipant: 'getParticipant',
      postData: 'save'
    }),
    loadItem (id) {
      if (id) {
        this.title = 'Editar Participante'
        this.getParticipant(id).then((data) => {
          this.participant = this.data
          if (this.data.rooms) {
            this.participant.rooms = this.data.rooms.data
          }
        }).then(() => {
          this.loading = false
        })
      }
    },
    loadRooms () {
      roomService.list({ type: 1 }).then((res) => {
        this.optionsRooms = res.data.data
      })
    },
    open (id = null) {
      this.modal = true
      this.loading = true
      this.loadRooms()
      this.GetSetting()
      if (id) {
        this.loadItem(id)
        return
      }
      this.loading = false
    },
    async saveParticipant () {
      this.submitted = true
      this.$v.participant.$touch()
      if (this.$v.participant.$invalid) {
        window.scroll(0, 0)
        return
      }
      this.loading = true
      if (typeof (this.selected) === 'object') {
        this.participant.district = this.selected.value
      }
      await this.postData(this.participant).then(res => {
        let message = 'Sucesso: Dados armazenados com sucesso.'
        let delay = 2500
        successMessage(message, delay)
        this.close()
        this.$emit('created')
      }).catch((err) => {
        errorMessage('Alerta: ' + err.message)
        this.loading = false
      })
    },
    close () {
      this.modal = false
      this.participant = {}
      this.$v.$reset()
    }
  },
  computed: {
    ...mapState('event', ['setting']),
    ...mapState('participant', { data: 'participant' })
  },
  watch: {
    modal () {
      if (!this.modal) {
        this.close()
      }
    },
    'participant.district' (value) {
      this.selected = this.states.filter(o => o.value === value)[0]
    }
  }
}
</script>
<style scoped>
.vld-overlay.is-full-page {
    z-index: 1051 !important;
}
</style>
