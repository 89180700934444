<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row" v-if="!setting.id">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="media">
              <div class="media-body overflow-hidden text-center mt-4">
                <h5 class="text-truncate font-size-15 ">Olá, {{ user.name }}.</h5>
                <p class="text-muted">Indentificamos que a sua plataforma ainda não está configurada.</p>
                <router-link :to="{ name: 'event' }">
                  <b-button variant="primary" size="lg" class="mt-4 mb-4" >
                    <i class="bx bx-cog font-size-16 align-middle mr-2"></i> CONFIGURAR AGORA!
                  </b-button>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row" v-if="setting.id">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <form @submit.prevent="saveSetting()">
              <div class="form-group row mb-4">
                <label for="session_register" class="col-form-label col-lg-3">Liberar Cadastro</label>
                <div class="col-lg-9">
                  <select
                    class="custom-select"
                    name="session_register"
                    id="session_register"
                    v-model="setting.session_register"
                    :class="{
                      'is-invalid': submitted && $v.setting.session_register.$error,
                    }">
                    <option value="1">Autorizado</option>
                    <option value="0">Suspenso</option>
                  </select>
                </div>
              </div>
              <div class="form-group row mb-4">
                <label for="send_email" class="col-form-label col-lg-3">Confirmação de Cadastro</label>
                <div class="col-lg-9">
                  <select
                    class="custom-select"
                    name="send_email"
                    id="send_email"
                    v-model="setting.send_email"
                    :class="{
                      'is-invalid': submitted && $v.setting.send_email.$error,
                    }">
                    <option value="1">Enviar e-mail de confirmação</option>
                    <option value="0">Não enviar e-mail</option>
                  </select>
                </div>
              </div>
              <div class="form-group row mb-4">
                <label for="name" class="col-form-label col-lg-3">Liberar Autenticação</label>
                <div class="col-lg-9">
                  <select
                    class="custom-select"
                    name="session_login"
                    id="session_login"
                    v-model="setting.session_login"
                    :class="{
                      'is-invalid': submitted && $v.setting.session_login.$error,
                    }">
                    <option value="1">Autorizado</option>
                    <option value="0">Suspenso</option>
                  </select>
                </div>
              </div>
              <div class="form-group row mb-4" v-if="parseInt(setting.session_login) === 0">
                <label for="message_login" class="col-form-label col-lg-3">Mensagem</label>
                <div class="col-lg-9">
                  <input
                    id="message_login"
                    name="message_login"
                    v-model="setting.message_login"
                    type="text"
                    class="form-control"
                    placeholder="Informe uma mensagem para o login suspenso."
                  />
                </div>
              </div>
              <div class="form-group row mb-4">
                <label for="access_type" class="col-form-label col-lg-3">Tipo de Autenticação</label>
                <div class="col-lg-9">
                  <select
                    class="custom-select"
                    name="access_type"
                    id="access_type"
                    v-model="setting.access_type"
                    :class="{
                      'is-invalid': submitted && $v.setting.access_type.$error,
                    }">
                    <option value="1">Email e senha</option>
                    <option value="2">E-mail</option>
                  </select>
                </div>
              </div>
              <div class="form-group row mb-4">
                <label for="name" class="col-form-label col-lg-3">Banner Login</label>
                <div class="col-lg-9 actions">
                  <input
                    ref="bannerlogin"
                    type="file"
                    name="bannerlogin"
                    accept="image/*"
                    @change="setImageBL"
                  />
                  <div class="popup-gallery border-dashed link" style="max-width:132px" v-if="setting.banner_login">
                    <img :src="setting.banner_login" width="120" @click="singleImage = true" />
                    <vue-easy-lightbox
                      :visible="singleImage"
                      :imgs="setting.banner_login"
                      @hide="singleImage = false"
                    ></vue-easy-lightbox>
                  </div>
                </div>
              </div>
              <div class="mt-5">
                <button type="submit" class="btn btn-primary mr-1">
                  Salvar
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
<script>
import VueEasyLightbox from 'vue-easy-lightbox'
import Layout from '@/components/layout/vertical'
import PageHeader from '@/components/page-header'
import appConfig from '@/app.config'
import { errorMessage, successMessage } from '@/components/alertsMessages'
import { mapActions, mapState } from 'vuex'

export default {
  components: {
    VueEasyLightbox,
    Layout,
    PageHeader
  },
  name: 'Evento',
  page: {
    title: 'Autenticação',
    meta: [{
      name: 'description',
      content: appConfig.description
    }]
  },
  data () {
    return {
      loading: true,
      singleImage: false,
      imgSrc: '',
      imgSrcBannerLogin: '',
      cropImg: '',
      cropBannerLogin: '',
      submitted: false,
      configEvent: false,
      title: 'Autenticação',
      items: [{
        text: 'Meu Evento',
        href: '/'
      },
      {
        text: 'Autenticação',
        active: true
      }
      ]
    }
  },
  mounted () {
    let loader = this.$loading.show()
    this.GetSetting().then(() => {
      loader.hide()
    })
  },
  computed: {
    ...mapState('auth', ['user']),
    ...mapState('event', ['setting'])
  },
  methods: {
    ...mapActions('event', {
      GetSetting: 'GetSetting',
      postSetting: 'SaveSetting'
    }),
    initSetting () {
      this.configEvent = true
    },
    setImageBL (e) {
      const file = e.target.files[0]
      if (file.type.indexOf('image/') === -1) {
        errorMessage('Alerta: Por favor selecione uma imagem.')
        return
      }
      const isLt2M = file.size / 1024 / 1024 < 4
      if (!isLt2M) {
        errorMessage('Alerta: Tamanho máximo 4MB!')
        return
      }
      if (typeof FileReader === 'function') {
        this.imgSrcBannerLogin = file
      } else {
        errorMessage('ERROR: Browser não suporta FileReader')
      }
    },
    async saveSetting () {
      let loader = this.$loading.show()
      try {
        const formData = new FormData()
        formData.append('id', this.setting.id)
        formData.append('session_register', this.setting.session_register)
        formData.append('send_email', this.setting.send_email)
        formData.append('session_login', this.setting.session_login)
        formData.append('message_login', this.setting.message_login)
        formData.append('access_type', this.setting.access_type)
        if (this.imgSrcBannerLogin) {
          let ext = this.imgSrcBannerLogin.type.split('/')[1]
          formData.append('banner_login', this.imgSrcBannerLogin, 'bannerLogin.' + ext)
        }
        await this.postSetting(formData).then(() => {
          let message = 'Sucesso: Dados atualizados com sucesso.'
          let delay = 2500
          successMessage(message, delay)
          loader.hide()
        })
      } catch (err) {
        console.log(err)
        errorMessage('Alerta: ' + err.response.data.message)
        loader.hide()
      }
    }
  }
}
</script>

<style scoped>
input[type="file"] {
  display: block;
}
.link { cursor: pointer; }
.preview-lg {
  width: 100%;
  height: calc(372px * (9 / 16));
  overflow: hidden;
}
.logo-preview {
  width:200px;
  overflow:hidden;
  height: 65px;
}
.actions {
  margin-top: 1rem;
}
.actions a {
  display: inline-block;
  padding: 5px 15px;
  background: #0062CC;
  color: white;
  text-decoration: none;
  border-radius: 3px;
  margin-right: 1rem;
  margin-bottom: 1rem;
}
.bg-black { background-color: #afafaf; opacity: 1; }
.border-dashed {
  border: 2px dashed #ced4da !important;
  padding: 4px;
}
</style>
