<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <form @submit.prevent="saveRoom()">
          <div class="card">
            <div class="card-body">
              <h4 class="card-title mb-4">Informações da Sala</h4>
              <div class="row">
                <div class="col-sm-4">
                  <input
                    ref="thumb"
                    type="file"
                    name="thumb"
                    accept="image/*"
                    @change="setImage"
                  />
                  <div class="link" @click.prevent="showChooser">
                    <div class="dropzone" v-if="!cropImg && !room.thumb_image">
                      <div class="dropzone-custom-content text-center ">
                        <i class="display-4 text-muted bx bxs-cloud-upload mt-4"></i>
                        <h5 class="text-muted ">Selecionar imagem</h5>
                      </div>
                    </div>
                    <img :src="cropImg || (room.thumb_image)" v-if="cropImg || room.thumb_image" class="rounded thumb border-light">
                  </div>
                </div>
                <div class="col-sm-8">
                  <div class="row">
                    <div class="col-sm-12">
                      <div class="form-group">
                        <label for="name">Nome</label>
                        <input
                          id="name"
                          v-model="room.name"
                          name="name"
                          type="text"
                          class="form-control"
                          :class="{
                            'is-invalid': submitted && $v.room.name.$error,
                          }"
                        />
                        <div
                          v-if="submitted && !$v.room.name.required"
                          class="invalid-feedback"
                        >
                          O campo nome é obrigatório
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-4">
                      <div class="form-group">
                        <label for="title">Tipo</label>
                        <select
                          class="custom-select"
                          name="allow_users"
                          id="allow_users"
                          v-model="room.allow_users"
                          :class="{
                            'is-invalid': submitted && $v.room.allow_users.$error,
                          }">
                          <option value="0">Todos usuários</option>
                          <option value="1">Restrita</option>
                        </select>
                        <div
                          v-if="submitted && !$v.room.allow_users.required"
                          class="invalid-feedback"
                        >
                        O campo tipo é obrigatório
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-4">
                      <div class="form-group">
                        <label for="title">Abertura</label>
                        <date-picker
                          v-model="room.start_date"
                          type="datetime"
                          :first-day-of-week="1"
                          lang="pt-br"
                          format="DD/MM/YYYY HH:mm"
                          :class="{
                            'is-invalid': submitted && $v.room.start_date.$error,
                          }"
                        >
                        </date-picker>
                        <div
                          v-if="submitted && !$v.room.start_date.required"
                          class="invalid-feedback"
                        >
                        O campo Abertura é obrigatório
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-4">
                      <div class="form-group">
                        <label for="title">Encerramento</label>
                        <date-picker
                          v-model="room.end_date"
                          type="datetime"
                          :first-day-of-week="1"
                          lang="pt-br"
                          format="DD/MM/YYYY HH:mm"
                          :class="{
                            'is-invalid': submitted && $v.room.end_date.$error,
                          }"
                        >
                        </date-picker>
                        <div
                          v-if="submitted && !$v.room.end_date.required"
                          class="invalid-feedback"
                        >
                        O campo Encerramento é obrigatório
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mt-4">
                <div class="col-sm-12">
                  <div class="form-group">
                    <label for="title">Descrição</label>
                    <ckeditor
                      :editor="editor"
                      v-model="room.description"
                      :config="editorConfig"
                    >
                    </ckeditor>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card">
            <div class="card-body">
              <div class="float-right">
                <button type="buton" @click="cancelar()" class="btn btn-dark mr-4">
                  Cancelar
                </button>
                <button type="submit" class="btn btn-primary mr-1">
                  Salvar
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </Layout>
</template>
<script>
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/locale/pt-br'
import CKEditor from '@ckeditor/ckeditor5-vue'
import FullEditor from 'ckeditor5-build-full'
import Layout from '@/components/layout/vertical'
import PageHeader from '@/components/page-header'
import appConfig from '@/app.config'
import { errorMessage, successMessage } from '@/components/alertsMessages'
import { mapActions, mapState } from 'vuex'
import { required } from 'vuelidate/lib/validators'

export default {
  components: {
    DatePicker,
    ckeditor: CKEditor.component,
    Layout,
    PageHeader
  },
  name: 'Sala',
  page: {
    title: 'Sala',
    meta: [{
      name: 'description',
      content: appConfig.description
    }]
  },
  data () {
    return {
      room: {
        id: '',
        name: '',
        title: '',
        description: '',
        thumb_image: '',
        allow_users: 0
      },
      editor: FullEditor,
      editorConfig: {},
      thumb: require('@/assets/images/image-default.png'),
      imgSrc: '',
      cropImg: '',
      submitted: false,
      title: 'Sala',
      items: [{
        text: 'Salas',
        href: '/rooms'
      },
      {
        text: 'Nova Sala',
        active: true
      }
      ]
    }
  },
  validations: {
    room: {
      name: { required },
      allow_users: { required },
      description: { required },
      start_date: { required },
      end_date: { required }
    }
  },
  mounted () {
    this.loadItem()
  },
  computed: {
    ...mapState('room', { data: 'room' })
  },
  methods: {
    ...mapActions('room', {
      fetch: 'GetRoom',
      postData: 'SaveRoom'
    }),
    cancelar () {
      this.$router.push({ name: 'rooms' })
    },
    loadItem () {
      if (this.$route.params.id !== undefined) {
        let loader = this.$loading.show()
        this.items[1].text = 'Editar Sala'
        this.fetch(this.$route.params.id)
          .then(() => {
            this.room = this.data
            this.room.start_date = this.$moment(this.room.start_date, 'YYYY-MM-DD HH:mm').toDate()
            this.room.end_date = this.$moment(this.room.end_date, 'YYYY-MM-DD HH:mm').toDate()
          }).then(() => {
            loader.hide()
          })
      }
      let loader = this.$loading.show()
      loader.hide()
    },
    setImage (e) {
      const file = e.target.files[0]
      if (file.type.indexOf('image/') === -1) {
        errorMessage('Alerta: Por favor selecione uma imagem.')
        return
      }
      const isLt2M = file.size / 1024 / 1024 < 2
      if (!isLt2M) {
        errorMessage('Alerta: Tamanho máximo 2MB!')
        return
      }
      if (typeof FileReader === 'function') {
        const reader = new FileReader()
        reader.onload = (event) => {
          this.cropImg = event.target.result
        }
        reader.readAsDataURL(file)
        this.imgSrc = file
      } else {
        errorMessage('ERROR: Browser não suporta FileReader')
      }
    },
    showChooser () {
      this.$refs.thumb.click()
    },
    async saveRoom () {
      this.submitted = true
      this.$v.room.$touch()
      if (this.$v.room.$invalid) {
        window.scroll(0, 0)
        return
      }
      let loader = this.$loading.show()
      const formData = new FormData()
      formData.append('id', this.room.id)
      formData.append('name', this.room.name)
      formData.append('allow_users', this.room.allow_users)
      formData.append('description', this.room.description)
      formData.append('start_date', this.$moment(this.room.start_date).format('YYYY-MM-DD HH:mm:ss'))
      formData.append('end_date', this.$moment(this.room.end_date).format('YYYY-MM-DD HH:mm:ss'))
      if (this.imgSrc) {
        let ext = this.imgSrc.type.split('/')[1]
        formData.append('thumb_image', this.imgSrc, 'thumbnail.' + ext)
      }
      await this.postData(formData).then(res => {
        let message = 'Sucesso: Dados atualizados com sucesso.'
        let delay = 2500
        loader.hide()
        successMessage(message, delay)
        this.cancelar()
      }).catch((err) => {
        errorMessage('Alerta: ' + err.message)
        loader.hide()
      })
    }
  }
}
</script>

<style scoped>
input[type="file"] {
  display: none;
}
.link { cursor: pointer; }
.thumb {
  max-width: 100%;
  max-height: 168px;
  min-height: 168px;
}
.dropzone { max-width: 400px; padding: 0px !important; max-height: 170px; overflow: hidden;}
.border-light {
  padding: 4px;
  display: flex;
  background: #f1f1f1;
  border-radius: 0.25rem;
}
</style>
