<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row" v-if="!setting.id">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="media">
              <div class="media-body overflow-hidden text-center mt-4">
                <h5 class="text-truncate font-size-15 ">Olá, {{ user.name }}.</h5>
                <p class="text-muted">Indentificamos que a sua plataforma ainda não está configurada.</p>
                <router-link :to="{ name: 'event' }">
                  <b-button variant="primary" size="lg" class="mt-4 mb-4" >
                    <i class="bx bx-cog font-size-16 align-middle mr-2"></i> CONFIGURAR AGORA!
                  </b-button>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row" v-if="setting.id">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
              <div class="form-group row mb-4">
                <label for="certificate" class="col-form-label col-lg-2">Liberar Certificado</label>
                <div class="col-lg-10">
                  <select
                    class="custom-select"
                    name="certificate"
                    id="certificate"
                    v-model="setting.certificate"
                    :class="{
                      'is-invalid': submitted && $v.setting.certificate.$error,
                    }">
                    <option value="1">Autorizado</option>
                    <option value="0">Suspenso</option>
                  </select>
                </div>
              </div>
              <div class="form-group row mb-4">
                <label for="type_certificate" class="col-form-label col-lg-2">Tipo Certificado</label>
                <div class="col-lg-10">
                  <select
                    class="custom-select"
                    name="type_certificate"
                    id="type_certificate"
                    v-model="setting.type_certificate"
                    :class="{
                      'is-invalid': submitted && $v.setting.type_certificate.$error,
                    }">
                    <option value="0">Livre</option>
                    <option value="1">Precisa responder pesquisa de satisfação</option>
                  </select>
                </div>
              </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row" v-if="setting.id">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-sm-8">
                <h4 class="card-title mb-4 ">Customização</h4>
              </div>
              <div class="col-sm-4">
                <div class="float-right">
                  <button type="button" class="btn btn-success btn-sm btn-rounded" @click="addPage()">
                    <i class="mdi mdi-plus mr-1"></i> Adicionar página
                  </button>
                </div>
              </div>
            </div>
            <div class="row separate" v-for="(cert, i) in certificates" :key=i>
              <div class="col-sm-12 mb-4">
                <button class="btn btn-sm btn-dark">
                  Página {{ i + 1 }}
                </button>
                <button v-if="i > 0" class="btn btn-sm btn-danger ml-1" @click="delPage(i)">
                  Remover
                </button>
              </div>
              <div class="col-sm-12 ml-2">
                <div v-if="i === 0">
                  <div class="form-group row mb-4">
                    <label for="position_x" class="col-form-label col-lg-2">Posição X</label>
                    <div class="col-sm-2">
                      <input
                        type="number"
                        class="form-control"
                        name="position_x"
                        id="position_x"
                        v-model="cert.position_x"
                      >
                    </div>
                    <label for="position_y" class="col-form-label col-lg-1">Posição Y</label>
                    <div class="col-sm-2">
                      <input
                        type="number"
                        class="form-control"
                        name="position_y"
                        id="position_y"
                        v-model="cert.position_y"
                      >
                    </div>
                  </div>
                  <div class="form-group row mb-4">
                    <label for="font_color" class="col-form-label col-lg-2">Cor do texto (RGB)</label>
                    <div class="col-sm-2">
                      <input
                        type="text"
                        class="form-control"
                        name="font_color"
                        id="font_color"
                        v-model="cert.font_color"
                      >
                    </div>
                    <label for="font_size" class="col-form-label col-lg-1">Tamanho texto</label>
                    <div class="col-sm-2">
                      <input
                        type="number"
                        class="form-control"
                        name="font_size"
                        id="font_size"
                        v-model="cert.font_size"
                      >
                    </div>
                  </div>
                  <p class="text-muted font-13">Posicionamento, tamanho e cor para o nome do aluno no certificado.</p>
                </div>
                <div class="form-group row mb-4">
                  <label for="image_certificate" class="col-form-label col-lg-2">Imagem Certificado</label>
                  <div class="col-lg-5">
                    <b-form-file
                      v-model="cert.file"
                      browse-text="Procurar"
                      accept="image/*"
                      :ref="`image_certificate${i}`"
                      placeholder="Escolha uma imagem para o seu certificado"
                      drop-placeholder="Jogque arquivo aqui..."
                    ></b-form-file>
                  </div>
                </div>
                <p class="text-muted font-13">Imagem de fundo do certificado deve ser PNG com tamanho máximo de 2MB</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <button type="submit" class="btn btn-primary mr-1 float-right" @click="saveSetting()">
              Salvar
            </button>
            <button type="submit" class="btn btn-dark mr-1 float-right" @click="previewCert()">
              Ver Certificado
            </button>
          </div>
        </div>
      </div>
    </div>
    <b-modal
      id="modal-center"
      ref="modal-certificate"
      size="lg"
      centered
      title="Alterar Imagem do Certificado"
      title-class="font-18"
      @hidden="cancel('image_certificate')"
      @ok="crop"
    >
      <div class="img-cropper border-dashed" v-show="imgSrc">
        <vue-cropper
          ref="cropper"
          :src="imgSrc"
          preview=".preview"
        />
      </div>
    </b-modal>
  </Layout>
</template>
<script>
import VueCropper from 'vue-cropperjs'
import 'cropperjs/dist/cropper.css'
import Layout from '@/components/layout/vertical'
import PageHeader from '@/components/page-header'
import appConfig from '@/app.config'
import { errorMessage, successMessage } from '@/components/alertsMessages'
import { mapActions, mapState } from 'vuex'

export default {
  components: {
    VueCropper,
    Layout,
    PageHeader
  },
  name: 'Evento',
  page: {
    title: 'Certificação',
    meta: [{
      name: 'description',
      content: appConfig.description
    }]
  },
  data () {
    return {
      loading: true,
      indexImage: '',
      singleImage: false,
      imgSrc: '',
      cropImg: '',
      submitted: false,
      certificates: [
        {
          font_size: 16,
          font_color: '255,255,255',
          position_x: 88,
          position_y: 26,
          cropImg: '',
          picture: ''
        }
      ],
      configEvent: false,
      title: 'Certificação',
      items: [{
        text: 'Meu Evento',
        href: '/'
      },
      {
        text: 'Certificação',
        active: true
      }
      ]
    }
  },
  mounted () {
    let loader = this.$loading.show()
    this.GetSetting().then(() => {
      if (this.setting.certificates) {
        this.certificates = this.setting.certificates
      }
      loader.hide()
    })
  },
  computed: {
    ...mapState('auth', ['user']),
    ...mapState('event', ['setting'])
  },
  methods: {
    ...mapActions('event', {
      GetSetting: 'GetSetting',
      postSetting: 'SaveSetting',
      deletePageCert: 'DeletePageCert'
    }),
    initSetting () {
      this.configEvent = true
    },
    addPage () {
      const page = {
        font_size: '',
        font_color: '',
        position_x: '',
        position_y: '',
        image: ''
      }
      this.certificates.push(page)
    },
    delPage (idx) {
      const page = this.certificates[idx]
      if (page.id) {
        try {
          this.deletePageCert({ id: page.id }).then((res) => {
            successMessage('Sucesso: Página removida.', 2500)
            this.certificates.splice(idx, 1)
          }).catch((err) => {
            errorMessage('Alerta: ' + err.message)
          })
        } catch (err) {
          console.log(err)
        }
        return
      }
      this.certificates.splice(idx, 1)
    },
    crop () {
      // get image data for post processing, e.g. upload or setting image src
      this.certificates[this.indexImage].cropImg = this.$refs.cropper.getCroppedCanvas().toDataURL()
      this.$refs.cropper.getCroppedCanvas().toBlob((blob) => {
        console.log(blob)
        this.certificates[this.indexImage].binary = blob
      })
      setTimeout(() => {
        this.$refs.cropper.reset()
      }, 100)
    },
    cancel (ref) {
      this.$refs[`${ref}${this.indexImage}`].value = null
      this.imgSrc = ''
    },
    setImage (e) {
      console.log(e)
      const file = e.target.files[0]
      if (file.type.indexOf('image/') === -1) {
        errorMessage('Alerta: Por favor selecione uma imagem.')
        return
      }
      const isLt2M = file.size / 1024 / 1024 < 3
      if (!isLt2M) {
        errorMessage('Alerta: Tamanho máximo 3MB!')
        return
      }
      if (typeof FileReader === 'function') {
        const reader = new FileReader()
        reader.onload = (event) => {
          this.imgSrc = event.target.result
          // rebuild cropperjs with the updated source
          this.$refs.cropper.replace(event.target.result)
        }
        reader.readAsDataURL(file)
        this.$refs['modal-certificate'].show()
      } else {
        errorMessage('ERROR: Browser não suporta FileReader')
      }
    },
    showChooser (index) {
      this.$refs['image_certificate' + index][0].click()
      this.indexImage = index
    },
    previewCert () {
      const url = process.env.VUE_APP_DEFAULT_AUTH + '/admin/certificate'
      window.open(url)
    },
    async saveSetting () {
      let loader = this.$loading.show()
      try {
        const formData = new FormData()
        formData.append('id', this.setting.id)
        formData.append('type_certificate', this.setting.type_certificate)
        formData.append('certificate', this.setting.certificate)

        this.certificates.forEach((cert, index) => {
          formData.append(`certificates[${index}][positionX]`, cert.position_x)
          formData.append(`certificates[${index}][positionY]`, cert.position_y)
          formData.append(`certificates[${index}][fontSize]`, cert.font_size)
          formData.append(`certificates[${index}][fontColor]`, cert.font_color)
          if (cert.file) {
            formData.append(`certificates[${index}][picture]`, cert.file, `certificate_imagem${index}.png`)
          }
          if (cert.id) {
            formData.append(`certificates[${index}][id]`, cert.id)
          }
        })
        await this.postSetting(formData).then(() => {
          let message = 'Sucesso: Dados atualizados com sucesso.'
          let delay = 2500
          successMessage(message, delay)
          loader.hide()
        })
      } catch (err) {
        console.log(err)
        loader.hide()
        errorMessage('Alerta: ' + err.response.data.message)
      }
    }
  }
}
</script>
<style scoped>
.separate { border-bottom: 1px solid #ddd; margin: 10px;}
input[type="file"] {
  display: none;
}
.link { cursor: pointer; }
.preview-lg {
  width: 100%;
  height: calc(372px * (9 / 16));
  overflow: hidden;
}
.logo-preview {
  width:200px;
  overflow:hidden;
  height: 65px;
}
.actions {
  margin-top: 1rem;
}
.actions a {
  display: inline-block;
  padding: 5px 15px;
  background: #0062CC;
  color: white;
  text-decoration: none;
  border-radius: 3px;
  margin-right: 1rem;
  margin-bottom: 1rem;
}
.bg-black { background-color: #afafaf; opacity: 1; }
.border-dashed {
  border: 2px dashed #ced4da !important;
  padding: 4px;
}
</style>
