<template>
  <div>
    <b-modal v-model="modal" :title="title" size="lg" :no-enforce-focus="true" :no-close-on-backdrop="true">
      <loading
        :active.sync="loading"
        color="#556ee6"
        loader="dots"
        :can-cancel="false"
        :is-full-page="false">
      </loading>
      <div class="row">
        <div class="col-lg-12">
          <form @submit.prevent="saveBanner()" autocomplete="off">
            <div class="row">
              <div class="col-sm-3">
                <div class="form-group">
                  <label for="type_reward">Tipo</label>
                  <select
                    class="custom-select"
                    name="type_reward"
                    id="type_reward"
                    v-model="reward.type_reward"
                    :class="{
                      'is-invalid': submitted && $v.reward.type_reward.$error,
                    }">
                    <option value="1">Digital</option>
                    <option value="2">Físico</option>
                  </select>
                  <div
                    v-if="submitted && !$v.reward.type_reward.required"
                    class="invalid-feedback"
                  >
                    O campo tipo é obrigatório.
                  </div>
                </div>
              </div>
              <div class="col-sm-9">
                <div class="form-group">
                  <label for="title">Título</label>
                  <input
                    id="title"
                    v-model="reward.title"
                    name="title"
                    type="text"
                    class="form-control"
                    :class="{
                      'is-invalid': submitted && $v.reward.title.$error
                    }"
                  />
                  <div
                    v-if="submitted && !$v.reward.title.required"
                    class="invalid-feedback"
                  >
                    O campo título é obrigatório.
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-sm-3">
                <div class="form-group">
                  <label for="target">Alvo <small>(Qtd de indicações)</small></label>
                  <input
                    id="target"
                    v-model="reward.target"
                    name="target"
                    type="number"
                    class="form-control text-right"
                    :class="{
                      'is-invalid': submitted && $v.reward.target.$error
                    }"
                  />
                  <div
                    v-if="submitted && !$v.reward.target.required"
                    class="invalid-feedback"
                  >
                    O campo alvo é obrigatório.
                  </div>
                </div>
              </div>
              <div class="col-sm-3">
                <div class="form-group">
                  <label for="qty_rewards">Qtd de Recompensas</label>
                  <input
                    id="qty_rewards"
                    v-model="reward.qty_rewards"
                    name="qty_rewards"
                    type="number"
                    class="form-control text-right"
                    :class="{
                      'is-invalid': submitted && $v.reward.qty_rewards.$error
                    }"
                  />
                  <div
                    v-if="submitted && !$v.reward.qty_rewards.required"
                    class="invalid-feedback"
                  >
                    O campo Qtd é obrigatório.
                  </div>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group">
                  <label for="url_reward">URL da Recompensa</label>
                  <input
                    id="url_reward"
                    v-model="reward.url_reward"
                    name="url_reward"
                    type="text"
                    class="form-control"
                    :class="{
                      'is-invalid': submitted && $v.reward.url_reward.$error
                    }"
                  />
                  <div
                    v-if="submitted && !$v.reward.url_reward.$url"
                    class="invalid-feedback"
                  >
                    O campo url da recompensa precisa ser uma URL válida
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <template v-slot:modal-footer>
        <b-button variant="secondary" @click="close()">Cancelar</b-button>
        <button type="submit" class="btn btn-primary mr-1" @click="saveReward()">Salvar</button>
      </template>
    </b-modal>
  </div>
</template>
<script>
// Import component
import Loading from 'vue-loading-overlay'
// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css'
import { mapActions, mapState } from 'vuex'
import { required, url } from 'vuelidate/lib/validators'
import { errorMessage, successMessage } from '@/components/alertsMessages'

export default {
  components: {
    Loading
  },
  props: {
    idVideo: {
      type: Number || String,
      default: null
    }
  },
  data () {
    return {
      title: 'Nova Recompensa',
      loading: true,
      fullPage: true,
      submitted: false,
      reward: {},
      modal: false
    }
  },
  validations: {
    reward: {
      type_reward: { required },
      target: { required },
      qty_rewards: { required },
      title: { required },
      url_reward: { url }
    }
  },
  methods: {
    ...mapActions('indicationReward', {
      getReward: 'show',
      postData: 'save'
    }),
    loadItem (id) {
      if (id) {
        this.title = 'Editar Recompensa'
        this.getReward(id).then((data) => {
          this.reward = this.data
          console.log(this.reward)
        }).then(() => {
          this.loading = false
        })
      }
    },
    open (id = null) {
      this.modal = true
      this.loading = true
      if (id) {
        this.loadItem(id)
        return
      }
      this.loading = false
    },
    async saveReward () {
      this.submitted = true
      this.$v.reward.$touch()
      if (this.$v.reward.$invalid) {
        window.scroll(0, 0)
        return
      }
      this.loading = true
      await this.postData(this.reward).then(res => {
        let message = 'Sucesso: Dados armazenados com sucesso.'
        let delay = 2500
        successMessage(message, delay)
        this.close()
        this.$emit('created')
      }).catch((err) => {
        errorMessage('Alerta: ' + err.message)
        this.loading = false
      })
    },
    close () {
      this.modal = false
      this.reward = {}
      this.$v.$reset()
    }
  },
  computed: {
    ...mapState('indicationReward', { data: 'indicationreward' })
  },
  watch: {
    modal () {
      if (!this.modal) {
        this.close()
      }
    }
  }
}
</script>
<style scoped>
input[type="file"] {
  display: none;
}
.vld-overlay.is-full-page {
    z-index: 1051 !important;
}
</style>
