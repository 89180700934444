<template>
<div style="min-height: 325px" >
  <div class="row mb-2">
    <div class="col-md-12 text-center" v-show="symplaevents.length === 0">
      <h5 class="text-truncate font-size-14 p-4 mt-2">Nenhum evento vinculado!</h5>
       <button type="button" class="btn btn-success btn-rounded mb-2 mr-2" @click="getEvents()">
          <i class="mdi mdi-plus mr-1"></i> Buscar Evento
        </button>
      <br />
    </div>
    <!-- end col-->
  </div>
  <div class="table-responsive" v-if="symplaevents.length > 0">
    <table class="table table-centered table-nowrap table-hover">
      <thead>
        <tr>
          <th>Código</th>
          <th>Descrição</th>
          <th>Ação</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(list, idx) in symplaevents" :key="idx" class="link">
          <td @click="editReward(list.id)">{{ list.event_id }}</td>
          <td @click="editReward(list.id)">{{ list.name }}</td>
          <td>
            <i class="fas fa-trash-alt text-danger mr-1" title="Desvincular" @click="deleteEvent(list.id)"></i>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="row" v-if="symplaevents.length > perPage">
    <div class="col">
      <div class="dataTables_paginate paging_simple_numbers float-right">
        <ul class="pagination pagination-rounded mb-0">
          <!-- pagination -->
          <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" @input="getListEvents"></b-pagination>
        </ul>
      </div>
    </div>
  </div>
  <event-modal v-model="modalForm" ref="formEvents" @createdEvent="getEvent()"></event-modal>
</div>
</template>
<script>
import Swal from 'sweetalert2'
import EventModal from './Form'
import { errorMessage, successMessage } from '@/components/alertsMessages'
import { mapActions, mapState } from 'vuex'

export default {
  components: {
    EventModal
  },
  name: 'sympla-events',
  data () {
    return {
      loading: true,
      modalForm: false,
      totalRows: 1,
      currentPage: 1,
      perPage: 5,
      idEvent: null
    }
  },
  mounted () {
    this.getAllEvents()
    this.loading = false
  },
  computed: {
    ...mapState('sympla', ['symplaevents', 'symplaeventpagination']),
    rows () {
      return this.symplaeventpagination.total
    }
  },
  methods: {
    ...mapActions('sympla', {
      getAllEvents: 'getAllEvents',
      deleteItem: 'deleteEvent'
    }),
    getEvents () {
      this.$refs.formEvents.open('')
      this.modalForm = true
    },
    deleteEvent (id) {
      Swal.fire({
        title: 'Você tem certeza ?',
        text: 'Essa ação não poderá ser revertida',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#f46a6a',
        cancelButtonColor: '#4a4848',
        cancelButtonText: 'CANCELAR',
        confirmButtonText: 'SIM, DESEJO EXCLUIR!'
      }).then(result => {
        if (result.value) {
          try {
            this.deleteItem(id).then((data) => {
              successMessage(data.data.message, 2500)
              this.getSymplaEvent()
            }).catch(error => {
              errorMessage('Alerta: ' + error.message)
            })
          } catch (err) {
            errorMessage('Alerta: ' + err.response.data.message)
          }
        }
      })
    },
    getSymplaEvent () {
      let loeader = this.$loading.show()
      let params = {
        limit: this.perPage,
        page: this.currentPage
      }
      this.params = params

      this.getAllEvents(this.params).then((res) => {
        // this.participants = res.data
        this.totalRows = this.symplaeventpagination.total
        this.currentPage = this.symplaeventpagination.current_page
        loeader.hide()
      }).catch(error => {
        console.log(error)
        loeader.hide()
      })
    }
  }
}
</script>
<style scoped>
.link { cursor: pointer; }
.swal2-popup {
  font-size: 11px !important;
}
.table-responsive { min-height: 300px;}
.float-sm-end { float: right }
</style>
