<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row mb-2">
              <div class="col-sm-4">
                <div class="">
                    <button type="button" class="btn btn-dark btn-rounded mb-2 mr-2" @click="formRules()">
                      Regras/Regulamento
                    </button>
                  </div>
              </div>
              <div class="col-sm-8">
                <div class="float-sm-end d-flex">
                  <span class="text-muted mt-1 font-size-14 p-1 mr-1">Ranking</span>
                  <div class="text-sm-right me-2 mt-2 green-custom-check">
                    <b-form-checkbox v-model="setting.gamification_ranking" @change="toggleRankingGamification()" switch size="lg"></b-form-checkbox>
                  </div>
                  <span class="text-muted mt-1 font-size-14 p-1 mr-1">Status</span>
                  <div class="text-sm-right me-2 mt-2 green-custom-check">
                    <b-form-checkbox v-model="setting.gamification" @change="toggleStatusGamification()" switch size="lg"></b-form-checkbox>
                  </div>
                  <div class="text-sm-right">
                    <button type="button" class="btn btn-success btn-rounded mb-2 mr-2" @click="newSetting()">
                      <i class="mdi mdi-plus mr-1"></i> Add. Objetivo
                    </button>
                  </div>
                  <div class="text-sm-right">
                    <button type="button" class="btn btn-danger btn-rounded mb-2 mr-2" @click="modalAlertCleanRanking()">
                      <i class="fas fa-trash-alt text-white mr-1"></i> Limpar
                    </button>
                  </div>
                </div>
              </div>
              <!-- end col-->
            </div>
            <div class="table-responsive">
              <table class="table table-centered table-nowrap table-hover">
                <thead>
                  <tr>
                    <th width="60%">Objetivo</th>
                    <th>Pontos</th>
                    <th>Status</th>
                    <th>Ações</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="list in gamifications" :key="list.id">
                    <td @click="editSetting(list.id)">{{ list.gamification.data.description }}</td>
                    <td @click="editSetting(list.id)">{{ list.points }}</td>
                    <td class="green-custom-check">
                      <b-form-checkbox v-model="list.status" switch @change="toggleStatus(list.id)"></b-form-checkbox>
                    </td>
                    <td>
                      <b-dropdown class="card-drop" variant="white" right toggle-class="p-0">
                        <template v-slot:button-content>
                          <i class="mdi mdi-dots-horizontal font-size-18"></i>
                        </template>
                        <b-dropdown-item @click="editSetting(list.id)">
                          <i class="fas fa-pencil-alt text-success mr-1"></i> Editar
                        </b-dropdown-item>
                        <b-dropdown-item @click="deleteSetting(list.id)">
                          <i class="fas fa-trash-alt text-danger mr-1"></i> Excluir
                        </b-dropdown-item>
                      </b-dropdown>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="row">
              <div class="col">
                <div class="dataTables_paginate paging_simple_numbers float-right">
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" @input="updateList"></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <modal-form v-model="modalForm" :idGamification="idGamification" ref="formGamification" @created="updateList"></modal-form>
    <modal-rule v-model="modalRule" ref="formRule"></modal-rule>
  </Layout>
</template>
<script>
import Swal from 'sweetalert2'
import Layout from '@/components/layout/vertical'
import PageHeader from '@/components/page-header'
import appConfig from '@/app.config'
import ModalForm from './Form'
import ModalRule from './FormRule'
import { errorMessage, successMessage } from '@/components/alertsMessages'
import { mapActions, mapState } from 'vuex'

export default {
  components: {
    Layout,
    PageHeader,
    ModalForm,
    ModalRule
  },
  name: 'gamification',
  page: {
    title: 'Gamificação',
    meta: [{
      name: 'description',
      content: appConfig.description
    }]
  },
  data () {
    return {
      modalForm: false,
      modalRule: false,
      params: {},
      totalRows: 1,
      currentPage: 1,
      idGamification: null,
      perPage: 20,
      statusLabel: ['Inativo', 'Ativo'],
      submitted: false,
      title: 'Gamificação',
      items: [{
        text: 'Gamificação'
      },
      {
        text: 'Configurações',
        active: true
      }
      ]
    }
  },
  mounted () {
    this.updateList()
    this.GetSetting()
    this.totalRows = this.pagination.total
    this.currentPage = this.pagination.current_page
    this.loading = false
  },
  computed: {
    ...mapState('event', ['setting']),
    ...mapState('gamification', ['gamifications', 'pagination']),
    rows () {
      return this.pagination.total
    }
  },
  methods: {
    ...mapActions('gamification', {
      getAll: 'getAll',
      changeStatus: 'changeStatus',
      changeGamification: 'changeStatusGamification',
      changeGamificationRanking: 'changeRankingGamification',
      deleteGamification: 'deleteSetting',
      resetGamification: 'resetRanking'
    }),
    ...mapActions('event', {
      GetSetting: 'GetSetting'
    }),
    formRules () {
      this.$refs.formRule.open()
      this.modalRule = true
    },
    newSetting () {
      this.$refs.formGamification.open('')
      this.modalForm = true
    },
    editSetting (id) {
      this.$refs.formGamification.open(id)
      this.modalForm = true
      this.idGamification = id
    },
    async toggleStatus (id) {
      try {
        await this.changeStatus(id).then((res) => {
          let message = 'Sucesso: Status atualizado com sucesso.'
          let delay = 2500
          successMessage(message, delay)
          this.updateList()
        }).catch((error) => {
          errorMessage('Alerta: ' + error.message)
        })
      } catch (err) {
        console.log(err)
        errorMessage('Alerta: ' + err.response.data.message)
      }
    },
    async toggleStatusGamification () {
      try {
        await this.changeGamification()
        let message = 'Sucesso: Status da gamificação atualizado com sucesso.'
        let delay = 2500
        successMessage(message, delay)
        this.GetSetting()
      } catch (err) {
        console.log(err)
        errorMessage('Alerta: ' + err.response.data.message)
      }
    },
    async toggleRankingGamification () {
      try {
        await this.changeGamificationRanking()
        let message = 'Sucesso: Status ranking gamificação atualizado com sucesso.'
        let delay = 2500
        successMessage(message, delay)
        this.GetSetting()
      } catch (err) {
        console.log(err)
        errorMessage('Alerta: ' + err.response.data.message)
      }
    },
    modalAlertCleanRanking () {
      Swal.fire({
        title: 'Você tem certeza que deseja RESETAR o ranking ?',
        text: 'Essa ação não poderá ser revertida. É aconselhado você exportar os dados do ranking antes de confirmar.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#f46a6a',
        cancelButtonColor: '#4a4848',
        cancelButtonText: 'CANCELAR',
        confirmButtonText: 'SIM, DESEJO RESETAR!'
      }).then(result => {
        if (result.value) {
          try {
            this.resetGamification().then((res) => {
              successMessage('Sucesso: Ranking resetado com sucesso.', 2500)
            }).catch((err) => {
              errorMessage('Alerta: ' + err.message)
            })
          } catch (err) {
            errorMessage('Alerta: ' + err.response.data.message)
          }
        }
      })
    },
    deleteSetting (id) {
      Swal.fire({
        title: 'Você tem certeza ?',
        text: 'Essa ação não poderá ser revertida',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#f46a6a',
        cancelButtonColor: '#4a4848',
        cancelButtonText: 'CANCELAR',
        confirmButtonText: 'SIM, DESEJO EXCLUIR!'
      }).then(result => {
        if (result.value) {
          try {
            this.deleteGamification(id).then((res) => {
              successMessage('Sucesso: Objetivo excluído com sucesso.', 2500)
              this.updateList()
            }).catch((err) => {
              errorMessage('Alerta: ' + err.message)
            })
          } catch (err) {
            errorMessage('Alerta: ' + err.response.data.message)
          }
        }
      })
    },
    getReport (filter) {
      let params = {
        include: 'gamification',
        search: this.search || null,
        limit: this.perPage,
        page: this.currentPage,
        domain: filter.domain || null,
        city: filter.city || null,
        district: filter.district || null,
        created_start: filter.created_start ? this.$moment(filter.created_start).format('YYYY-MM-DD') : null,
        created_end: filter.created_end ? this.$moment(filter.created_end).format('YYYY-MM-DD') : null,
        status: filter.status || null
      }
      this.params = params
      let loeader = this.$loading.show()
      this.getAll(this.params).then((res) => {
        // this.participants = res.data
        this.totalRows = this.pagination.total
        this.currentPage = this.pagination.current_page
        loeader.hide()
      }).catch(error => {
        console.log(error)
        loeader.hide()
      })
    },
    doSearch () {
      this.params = { ...this.params, search: this.search }
      this.getReport(this.params)
    },
    updateList () {
      this.getReport(this.params)
    }
  },
  watch: {
    // currentPage () { this.updateList() }
    // total () { this.updateList() },
  }
}
</script>
<style scoped>
.link { cursor: pointer; }
.swal2-popup {
  font-size: 11px !important;
}
.table-responsive { min-height: 400px;}
.float-sm-end { float: right }
</style>
