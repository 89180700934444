<template>
  <b-modal v-model="modal" :title="title" size="md" :no-enforce-focus="true" :no-close-on-backdrop="true">
    <loading
      :active.sync="loading"
      color="#556ee6"
      loader="dots"
      :can-cancel="false"
      :is-full-page="false">
    </loading>
    <div class="row">
      <div class="col-lg-12">
        <form @submit.prevent="initRaffle()" autocomplete="off">
          <div class="row">
            <div class="col-sm-8">
              <div class="form-group">
                <label for="type">Tipo</label>
                <select
                    class="custom-select"
                    name="type"
                    id="type"
                    v-model="raffle.type"
                    :class="{
                      'is-invalid': submitted && $v.raffle.type.$error,
                    }">
                    <option value="1">Usuários Online</option>
                    <option value="2">Todos</option>
                  </select>
                <div
                  v-if="submitted && !$v.raffle.type.required"
                  class="invalid-feedback"
                >
                  O campo tipo é obrigatório.
                </div>
              </div>
            </div>
            <div class="col-sm-4">
              <div class="form-group">
                <label for="qty">Quantidade</label>
                <input
                  id="qty"
                  v-model="raffle.qty"
                  name="qty"
                  type="number"
                  class="form-control text-right"
                  :class="{
                    'is-invalid': submitted && $v.raffle.qty.$error
                  }"
                />
                <div
                  v-if="submitted && !$v.raffle.qty.required"
                  class="invalid-feedback"
                >
                  O campo quantidade é obrigatório.
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
    <template v-slot:modal-footer>
      <b-button variant="secondary" @click="close()">Cancelar</b-button>
      <button type="submit" class="btn btn-primary mr-1" @click="initRaffle()">Sortear</button>
    </template>
  </b-modal>
</template>
<script>
// Import component
import Loading from 'vue-loading-overlay'
// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css'
import { mapActions } from 'vuex'
import { required } from 'vuelidate/lib/validators'
import { errorMessage, successMessage } from '@/components/alertsMessages'

export default {
  components: {
    Loading
  },
  data () {
    return {
      title: 'Novo Sorteio',
      loading: true,
      fullPage: true,
      submitted: false,
      raffle: {
        type: '',
        qty: 0
      },
      modal: false
    }
  },
  validations: {
    raffle: {
      type: { required },
      qty: { required }
    }
  },
  methods: {
    ...mapActions('raffle', {
      postData: 'save'
    }),
    open (id = null) {
      this.modal = true
      this.loading = false
    },
    async initRaffle () {
      this.submitted = true
      this.$v.raffle.$touch()
      if (this.$v.raffle.$invalid) {
        window.scroll(0, 0)
        return
      }
      this.loading = true
      await this.postData(this.raffle).then(res => {
        let message = 'Sucesso: Sorteio realizado com sucesso.'
        let delay = 2500
        successMessage(message, delay)
        this.close()
        this.$emit('created')
      }).catch((err) => {
        errorMessage('Alerta: ' + err.message)
        this.submitted = false
        this.loading = false
      })
    },
    close () {
      this.modal = false
      this.submitted = false
      this.raffle = {}
      this.$v.$reset()
    }
  },
  watch: {
    modal () {
      if (!this.modal) {
        this.close()
      }
    }
  }
}
</script>
<style scoped>
.vld-overlay.is-full-page {
    z-index: 1051 !important;
}
</style>
