<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row mb-2">
              <div class="col-sm-6">
              </div>
              <div class="col-sm-6">
                <div class="float-sm-end d-flex">
                  <span class="text-muted mt-1 font-size-14 p-1 mr-1">Timeline</span>
                  <div class="text-sm-right me-2 mt-2 green-custom-check">
                    <b-form-checkbox v-model="setting.timeline" @change="toogleTimeline()" switch size="lg"></b-form-checkbox>
                  </div>
                  <div class="text-sm-right">
                    <button type="button" class="btn btn-success btn-rounded mb-2 mr-2" @click="newBanner()">
                      <i class="mdi mdi-plus mr-1"></i> Novo Banner
                    </button>
                  </div>
                </div>
              </div>
              <!-- end col-->
            </div>
            <div class="table-responsive">
              <table class="table table-centered table-nowrap table-hover">
                <thead>
                  <tr>
                  <th scope="col" style="width: 20px"></th>
                    <th>Tipo</th>
                    <th>Tempo</th>
                    <th>Ação</th>
                    <th>Ações</th>
                  </tr>
                </thead>
                <draggable
                  v-model="banners"
                  :element="'tbody'"
                  tag="tbody"
                  :options="{animation:200, handle:'.mover'}"
                  @change="updateSequence"
                >
                  <tr v-for="list in banners" :key="list.id" class="link">
                    <td class="mover">
                      <span class="badge font-size-13 badge-soft-primary p-1 badge-num">{{ list.sequence }}</span>
                    </td>
                    <td class="mover">{{ list.type === 1 ? 'Pos. Esquerda' : 'Pos. Direita' }}</td>
                    <td class="mover">{{ list.limit_time }}</td>
                    <td class="mover">{{ list.action }}</td>
                    <td>
                      <!-- <a href="javascript: void(0);" @click="editBanner(list.id)" class="text-gray" alt="Editar"><i class="bx bx bxs-edit h3 m-0"></i></a>
                      <a href="javascript: void(0);" @click="toggleStatus(list.id)" class="text-dark">
                        <i class="bx h3 m-0" :class="{ 'bx-toggle-right': parseInt(list.status) === 1, 'bx-toggle-left': parseInt(list.status) === 0 }"></i>
                      </a>
                      <a href="javascript: void(0);" @click="deleteParticipant(list.id)" class="text-gray" alt="Excluir"><i class="bx bx bxs-trash h3 m-0"></i></a> -->
                      <b-dropdown class="card-drop" variant="white" right toggle-class="p-0">
                        <template v-slot:button-content>
                          <i class="mdi mdi-dots-horizontal font-size-18"></i>
                        </template>
                        <b-dropdown-item @click="editBanner(list.id)">
                          <i class="fas fa-pencil-alt text-success mr-1"></i> Editar
                        </b-dropdown-item>
                        <b-dropdown-item @click="deleteVideo(list.id)">
                          <i class="fas fa-trash-alt text-danger mr-1"></i> Excluir
                        </b-dropdown-item>
                      </b-dropdown>
                    </td>
                  </tr>
                </draggable>
              </table>
            </div>
            <div class="row">
              <div class="col">
                <div class="dataTables_paginate paging_simple_numbers float-right">
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" @input="updateList"></b-pagination>
                  </ul>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
    <modal-form v-model="modalForm" :idBanner="idBanner" ref="formBanner" @created="updateList"></modal-form>
  </Layout>
</template>
<script>
import draggable from 'vuedraggable'
import Swal from 'sweetalert2'
import Layout from '@/components/layout/vertical'
import PageHeader from '@/components/page-header'
import ModalForm from './Form'
import appConfig from '@/app.config'
import { errorMessage, successMessage } from '@/components/alertsMessages'
import { mapActions, mapState, mapGetters } from 'vuex'

export default {
  components: {
    draggable,
    Layout,
    PageHeader,
    ModalForm
  },
  name: 'timelinebanners',
  page: {
    title: 'Timeline',
    meta: [{
      name: 'description',
      content: appConfig.description
    }]
  },
  data () {
    return {
      loading: true,
      search: '',
      timeout: null,
      modalForm: false,
      params: {},
      totalRows: 1,
      currentPage: 1,
      idBanner: null,
      perPage: 20,
      statusLabel: ['Inativo', 'Ativo'],
      submitted: false,
      title: 'Timeline - Banners',
      items: [{
        text: 'Timeline'
      },
      {
        text: 'Configurações',
        active: true
      }
      ]
    }
  },
  mounted () {
    this.updateList()
    this.GetSetting()
    this.totalRows = this.pagination.total
    this.currentPage = this.pagination.current_page
    this.loading = false
  },
  computed: {
    ...mapState('event', ['setting']),
    ...mapGetters('timelineBanner', { data: 'TIMELINEBANNERS' }),
    ...mapState('timelineBanner', ['pagination']),
    rows () {
      return this.pagination.total
    },
    banners: {
      get () {
        return this.data
      },
      set (value) {
        let loader = this.$loading.show()
        this.updateSequence({ itens: value }).then(res => {
          loader.hide()
          successMessage('Sucesso: Ordem atualizada.', 2500)
        })
      }
    }
  },
  methods: {
    ...mapActions('timelineBanner', {
      getAll: 'getAll',
      changeStatus: 'changeStatus',
      updateSequence: 'orderList',
      deleteV: 'deleteVideo'
    }),
    ...mapActions('event', {
      GetSetting: 'GetSetting'
    }),
    showFilters () {
      this.$refs.modalFilter.open()
    },
    newBanner () {
      this.$refs.formBanner.open('')
      this.modalForm = true
    },
    editBanner (id) {
      this.$refs.formBanner.open(id)
      this.modalForm = true
      this.idBanner = id
    },
    async toogleTimeline () {
      try {
        await this.changeStatus()
        let message = 'Sucesso: Status timeline atualizado com sucesso.'
        let delay = 2500
        successMessage(message, delay)
        this.GetSetting()
      } catch (err) {
        console.log(err)
        errorMessage('Alerta: ' + err.response.data.message)
      }
    },
    deleteVideo (id) {
      Swal.fire({
        title: 'Você tem certeza ?',
        text: 'Essa ação não poderá ser revertida',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#f46a6a',
        cancelButtonColor: '#4a4848',
        cancelButtonText: 'CANCELAR',
        confirmButtonText: 'SIM, DESEJO EXCLUIR!'
      }).then(result => {
        if (result.value) {
          try {
            this.deleteV(id)
            successMessage('Sucesso: Vídeo excluído com sucesso.', 2500)
            this.updateList()
          } catch (err) {
            errorMessage('Alerta: ' + err.response.data.message)
          }
        }
      })
    },
    getReport (filter) {
      let params = {
        search: this.search || null,
        limit: this.perPage,
        page: this.currentPage,
        status: filter.status || null
      }
      this.params = params
      let loeader = this.$loading.show()
      this.getAll(this.params).then((res) => {
        // this.participants = res.data
        this.totalRows = this.pagination.total
        this.currentPage = this.pagination.current_page
        loeader.hide()
      }).catch(error => {
        console.log(error)
        loeader.hide()
      })
    },
    doSearch () {
      this.params = { ...this.params, search: this.search }
      this.getReport(this.params)
    },
    updateList () {
      this.getReport(this.params)
    }
  },
  watch: {
    // currentPage () { this.updateList() }
    // total () { this.updateList() },
  }
}
</script>
<style scoped>
.link { cursor: pointer; }
.swal2-popup {
  font-size: 11px !important;
}
.table-responsive { min-height: 300px;}
.float-sm-end { float: right }
</style>
