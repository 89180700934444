<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <form @submit.prevent="saveSpeaker()" autocomplete="off">
          <div class="card">
            <div class="card-body">
              <h4 class="card-title mb-4">Informações do Palestrante</h4>
              <div class="row">
                <div class="col-sm-2">
                  <input
                    ref="photo"
                    type="file"
                    name="photo"
                    accept="image/*"
                    @change="setImage"
                  />
                  <div class="mb-2">
                    <img :src="cropImg || (speaker.photo || avatar)" class=" rounded avatar-xl">
                  </div>
                  <a
                    class="p-4"
                    href="#"
                    role="button"
                    @click.prevent="showChooser"
                  >
                    Alterar Foto
                  </a>
                </div>
                <div class="col-sm-5">
                  <div class="form-group">
                    <label for="name">Nome</label>
                    <input
                      id="name"
                      v-model="speaker.name"
                      name="name"
                      type="text"
                      class="form-control"
                      :class="{
                        'is-invalid': submitted && $v.speaker.name.$error,
                      }"
                    />
                    <div
                      v-if="submitted && !$v.speaker.name.required"
                      class="invalid-feedback"
                    >
                      Nome do Palestrante é obrigatório.
                    </div>
                  </div>
                </div>
                <div class="col-sm-5">
                  <div class="form-group">
                    <label for="title">Ocupação</label>
                    <input
                      id="title"
                      v-model="speaker.title"
                      name="title"
                      type="text"
                      class="form-control"
                      :class="{
                        'is-invalid': submitted && $v.speaker.title.$error,
                      }"
                    />
                    <div
                      v-if="submitted && !$v.speaker.title.required"
                      class="invalid-feedback"
                    >
                      Ocupação do palestrante é obrigatório
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-10 offset-md-2">
                  <div class="form-group">
                    <label for="title">Descrição</label>
                    <ckeditor
                      :editor="editor"
                      v-model="speaker.description"
                      :config="editorConfig"
                    >
                    </ckeditor>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="card">
            <div class="card-body">
              <h4 class="card-title mb-4">Mídias Sociais</h4>
              <div class="row">
                <div class="col-sm-4">
                  <div class="form-group">
                    <label for="facebook">Facebook</label>
                    <input
                      id="facebook"
                      v-model="speaker.facebook"
                      name="facebook"
                      type="text"
                      class="form-control"
                      placeholder="Informe a URL do facebook"
                    />
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="form-group">
                    <label for="instagram">Instagram</label>
                    <input
                      id="instagram"
                      v-model="speaker.instagram"
                      name="instagram"
                      type="text"
                      class="form-control"
                      placeholder="Informe a URL do Instagram"
                    />
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="form-group">
                    <label for="linkedin">Linkedin</label>
                    <input
                      id="linkedin"
                      v-model="speaker.linkedin"
                      name="linkedin"
                      type="text"
                      class="form-control"
                      placeholder="Informe a URL do Linkedin"
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-4">
                  <div class="form-group">
                    <label for="twitter">Twitter</label>
                    <input
                      id="twitter"
                      v-model="speaker.twitter"
                      name="twitter"
                      type="text"
                      class="form-control"
                      placeholder="Informe a URL do Twitter"
                    />
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="form-group">
                    <label for="youtube">Youtube</label>
                    <input
                      id="youtube"
                      v-model="speaker.youtube"
                      name="youtube"
                      type="text"
                      class="form-control"
                      placeholder="Informe a URL do Youtube"
                    />
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="form-group">
                    <label for="website">Website ou E-mail</label>
                    <input
                      id="website"
                      v-model="speaker.website"
                      name="website"
                      type="text"
                      class="form-control"
                      placeholder="Informe a URL do Website ou E-mail"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card">
            <div class="card-body">
              <div class="float-right">
                <button type="buton" @click="cancelar()" class="btn btn-dark mr-4">
                  Cancelar
                </button>
                <button type="submit" class="btn btn-primary mr-1">
                  Salvar
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
    <b-modal
      id="modal-center"
      ref="modal-photo"
      centered
      title="Alterar Foto"
      title-class="font-18"
      @hidden="cancel('photo', 1)"
      @ok="cropImage"
    >
      <div class="img-cropper border-dashed" v-show="imgSrc">
        <vue-cropper
          ref="cropper"
          :src="imgSrc"
          preview=".preview"
        />
      </div>
    </b-modal>
  </Layout>
</template>
<script>
import CKEditor from '@ckeditor/ckeditor5-vue'
import FullEditor from 'ckeditor5-build-full'
import VueCropper from 'vue-cropperjs'
import 'cropperjs/dist/cropper.css'
import Layout from '@/components/layout/vertical'
import PageHeader from '@/components/page-header'
import appConfig from '@/app.config'
import { errorMessage, successMessage } from '@/components/alertsMessages'
import { mapActions, mapState } from 'vuex'
import { required } from 'vuelidate/lib/validators'

export default {
  components: {
    ckeditor: CKEditor.component,
    VueCropper,
    Layout,
    PageHeader
  },
  name: 'Palestrante',
  page: {
    title: 'Palestrante',
    meta: [{
      name: 'description',
      content: appConfig.description
    }]
  },
  data () {
    return {
      speaker: {
        id: '',
        name: '',
        title: '',
        description: '',
        photo: '',
        facebook: '',
        instagram: '',
        linkedin: '',
        twitter: '',
        youtube: '',
        website: ''
      },
      loading: true,
      editor: FullEditor,
      editorConfig: {},
      avatar: require('@/assets/images/users/user-default.png'),
      imgSrc: '',
      cropImg: '',
      submitted: false,
      title: 'Palestrante',
      items: [{
        text: 'Palestrantes',
        href: '/speakers'
      },
      {
        text: 'Novo Palestrante',
        active: true
      }
      ]
    }
  },
  validations: {
    speaker: {
      name: { required },
      title: { required }
    }
  },
  mounted () {
    this.loadItem()
  },
  computed: {
    ...mapState('speaker', { data: 'speaker' })
  },
  methods: {
    ...mapActions('speaker', {
      fetch: 'GetSpeaker',
      postData: 'SaveSpeaker'
    }),
    cancelar () {
      this.$router.push({ name: 'speakers' })
    },
    loadItem () {
      if (this.$route.params.id !== undefined) {
        let loader = this.$loading.show()
        this.items[1].text = 'Editar Palestrante'
        this.fetch(this.$route.params.id)
          .then(() => {
            this.speaker = this.data
          }).then(() => {
            loader.hide()
          })
        return
      }
      let loader = this.$loading.show()
      loader.hide()
    },
    cropImage () {
      // get image data for post processing, e.g. upload or setting image src
      this.cropImg = this.$refs.cropper.getCroppedCanvas().toDataURL()
      this.$refs.cropper.getCroppedCanvas().toBlob((blob) => {
        this.speaker.binaryphoto = blob
      })
    },
    cancel (ref) {
      this.$refs[`${ref}`].value = null
      this.imgSrc = ''
    },
    setImage (e) {
      const file = e.target.files[0]
      if (file.type.indexOf('image/') === -1) {
        errorMessage('Alerta: Por favor selecione uma imagem.')
        return
      }
      const isLt2M = file.size / 1024 / 1024 < 2
      if (!isLt2M) {
        errorMessage('Alerta: Tamanho máximo 2MB!')
        return
      }
      if (typeof FileReader === 'function') {
        const reader = new FileReader()
        reader.onload = (event) => {
          this.imgSrc = event.target.result
          // rebuild cropperjs with the updated source
          this.$refs.cropper.replace(event.target.result)
        }
        reader.readAsDataURL(file)
        this.$refs['modal-photo'].show()
      } else {
        errorMessage('ERROR: Browser não suporta FileReader')
      }
    },
    showChooser () {
      this.$refs.photo.click()
    },
    async saveSpeaker () {
      this.submitted = true
      window.scroll(0, 0)
      if (!this.speaker.photo && !this.speaker.binaryphoto) {
        errorMessage('Alerta: Foto do palestrante é obrigatória!')
        return
      }

      this.$v.speaker.$touch()
      if (this.$v.speaker.$invalid) {
        return
      }
      let loader = this.$loading.show()
      const formData = new FormData()
      formData.append('id', this.speaker.id)
      formData.append('name', this.speaker.name)
      formData.append('title', this.speaker.title)
      formData.append('description', this.speaker.description)
      formData.append('facebook', this.speaker.facebook)
      formData.append('instagram', this.speaker.instagram)
      formData.append('linkedin', this.speaker.linkedin)
      formData.append('twitter', this.speaker.twitter)
      formData.append('youtube', this.speaker.youtube)
      formData.append('website', this.speaker.website)
      if (this.speaker.binaryphoto) {
        formData.append('photo', this.speaker.binaryphoto, 'avatar.jpg')
      }
      await this.postData(formData).then(res => {
        let message = 'Sucesso: Dados atualizados com sucesso.'
        let delay = 2500
        loader.hide()
        successMessage(message, delay)
        this.cancelar()
      }).catch((err) => {
        errorMessage('Alerta: ' + err.message)
        loader.hide()
      })
    }
  }
}
</script>

<style>
input[type="file"] {
  display: none;
}
.link { cursor: pointer; }
.preview-lg {
  width: 100%;
  height: calc(372px * (9 / 16));
  overflow: hidden;
}
.logo-preview {
  width:200px;
  overflow:hidden;
  height: 65px;
}
.actions {
  margin-top: 1rem;
}
.actions a {
  display: inline-block;
  padding: 5px 15px;
  background: #0062CC;
  color: white;
  text-decoration: none;
  border-radius: 3px;
  margin-right: 1rem;
  margin-bottom: 1rem;
}
.bg-black { background-color: #afafaf; opacity: 1; }
.border-dashed {
  border: 2px dashed #ced4da !important;
  padding: 4px;
}
</style>
