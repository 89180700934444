<template>
  <div>
    <b-modal v-model="modal" :title="title" size="lg" :no-enforce-focus="true" :no-close-on-backdrop="true">
      <loading
        :active.sync="loading"
        color="#556ee6"
        loader="dots"
        :can-cancel="false"
        :is-full-page="false">
      </loading>
      <div class="row">
        <div class="col-lg-12">
          <form @submit.prevent="saveTicket()" autocomplete="off">
            <div class="row">
              <div class="col-sm-12">
                <div class="form-group">
                  <label for="name">Nome do ingresso</label>
                  <input
                    id="name"
                    v-model="ticket.name"
                    name="name"
                    type="text"
                    class="form-control"
                    :class="{
                      'is-invalid': submitted && $v.ticket.name.$error
                    }"
                  />
                  <div
                    v-if="submitted && !$v.ticket.name.required"
                    class="invalid-feedback"
                  >
                    O campo nome do ingresso é obrigatório.
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <template v-slot:modal-footer>
        <b-button variant="secondary" @click="close()">Cancelar</b-button>
        <button type="submit" class="btn btn-primary mr-1" @click="saveTicket()">Salvar</button>
      </template>
    </b-modal>
  </div>
</template>
<script>
// Import component
import Loading from 'vue-loading-overlay'
// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css'
import { mapActions, mapState } from 'vuex'
import { required } from 'vuelidate/lib/validators'
import { errorMessage, successMessage } from '@/components/alertsMessages'

export default {
  components: {
    Loading
  },
  props: {
    idVideo: {
      type: Number || String,
      default: null
    }
  },
  data () {
    return {
      title: 'Novo Ingresso',
      loading: true,
      fullPage: true,
      submitted: false,
      ticket: {},
      modal: false
    }
  },
  validations: {
    ticket: {
      name: { required }
    }
  },
  methods: {
    ...mapActions('sympla', {
      getTicket: 'showTicket',
      postData: 'save'
    }),
    loadItem (id) {
      if (id) {
        this.title = 'Editar Ingresso'
        this.getTicket(id).then((data) => {
          this.ticket = this.data
        }).then(() => {
          this.loading = false
        })
      }
    },
    open (id = null) {
      this.modal = true
      this.loading = true
      if (id) {
        this.loadItem(id)
        return
      }
      this.loading = false
    },
    async saveTicket () {
      this.submitted = true
      this.$v.ticket.$touch()
      if (this.$v.ticket.$invalid) {
        window.scroll(0, 0)
        return
      }
      this.loading = true
      await this.postData(this.ticket).then(res => {
        let message = 'Sucesso: Dados armazenados com sucesso.'
        let delay = 2500
        successMessage(message, delay)
        this.close()
        this.$emit('createdTicket')
      }).catch((err) => {
        errorMessage('Alerta: ' + err.message)
        this.loading = false
      })
    },
    close () {
      this.modal = false
      this.ticket = {}
      this.$v.$reset()
    }
  },
  computed: {
    ...mapState('sympla', { data: 'symplaticket' })
  },
  watch: {
    modal () {
      if (!this.modal) {
        this.close()
      }
    }
  }
}
</script>
<style scoped>
</style>
