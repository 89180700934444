<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row mb-2">
              <div class="col-sm-6"></div>
              <div class="col-sm-6">
                <div class="float-sm-end d-flex">
                  <span class="text-muted mt-1 font-size-14 p-1 mr-1">Status</span>
                  <div class="text-sm-right me-2 mt-2 green-custom-check">
                    <b-form-checkbox v-model="setting.satisfaction_survey" @change="toggleStatusSurvey()" switch size="lg"></b-form-checkbox>
                  </div>
                  <div class="text-sm-right">
                    <button type="button" class="btn btn-success btn-rounded mb-2 mr-2" @click="newSurveyAsking()">
                      <i class="mdi mdi-plus mr-1"></i> Inserir Pergunta
                    </button>
                  </div>
                </div>
              </div>
              <!-- end col-->
            </div>
            <div class="table-responsive">
              <table class="table table-centered table-nowrap table-hover">
                <thead>
                  <tr>
                    <th style="width: 20px;"></th>
                    <th style="width: 20px;">Seq</th>
                    <th>Pergunta</th>
                    <th>Opções</th>
                    <th>Tipo</th>
                    <th>Status</th>
                    <th>Ações</th>
                  </tr>
                </thead>
                <!-- <tbody> -->
                  <draggable
                    v-model="surveys"
                    :element="'tbody'"
                    :options="{animation:200, handle:'.mover'}"
                    @change="updateSequence"
                  >
                      <tr v-for="list in surveys" :key="list.id">
                      <td class="mover"><i class="bx bx-move"></i></td>
                        <td>
                            <span class="badge font-size-13 badge-soft-primary p-1 badge-num">{{ list.sequence }}</span>
                        </td>
                        <td>{{ list.asking}}</td>
                        <td>{{ list.answers.data.length }}</td>
                        <td>
                          <i :class="{
                            'mdi mdi-order-bool-descending font-size-16': `${parseInt(list.asking_type)}` === '1',
                            'dripicons-checklist font-size-16': `${parseInt(list.asking_type)}` === '2',
                            'bx bx-text font-size-16': `${parseInt(list.asking_type)}` === '3',
                            'mdi mdi-numeric font-size-16': `${parseInt(list.asking_type)}` === '4',
                            'dripicons-star font-size-16': `${parseInt(list.asking_type)}` === '5',
                          }"></i>
                        </td>
                        <td>
                          <span class="badge badge-pill font-size-12" :class="{
                            'badge-soft-success': `${parseInt(list.status)}` === '1',
                            'badge-soft-danger': `${parseInt(list.status)}` === '0',
                          }">{{ statusLabel[list.status] }}</span>
                        </td>
                        <td>
                          <b-dropdown class="card-drop" variant="white" right toggle-class="p-0">
                            <template v-slot:button-content>
                              <i class="mdi mdi-dots-horizontal font-size-18"></i>
                            </template>
                            <b-dropdown-item @click="editSurveyAsking(list.id)">
                              <i class="fas fa-pencil-alt text-success mr-1"></i> Editar
                            </b-dropdown-item>
                            <b-dropdown-item @click="toggleStatus(list.id)">
                              <i class="fas fa-circle mr-1" :class="{ 'text-danger': parseInt(list.status) === 1, 'text-success': parseInt(list.status) === 0 }"></i> {{ list.status ? 'Inativar' : 'Ativar' }}</b-dropdown-item>
                            <b-dropdown-item @click="deleteSurveyAsking(list.id)">
                              <i class="fas fa-trash-alt text-danger mr-1"></i> Excluir
                            </b-dropdown-item>
                          </b-dropdown>
                        </td>
                      </tr>
                  </draggable>
                <!-- </tbody> -->
              </table>
            </div>
            <div class="row">
              <div class="col">
                <div class="dataTables_paginate paging_simple_numbers float-right">
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" @input="updateList"></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <modal-form v-model="modalForm" :idSurvey="idSurvey" ref="formSurvey" @created="updateList"></modal-form>
  </Layout>
</template>
<script>
import draggable from 'vuedraggable'
import Swal from 'sweetalert2'
import Layout from '@/components/layout/vertical'
import ModalForm from './Form'
import PageHeader from '@/components/page-header'
import appConfig from '@/app.config'
import { errorMessage, successMessage } from '@/components/alertsMessages'
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
  components: {
    ModalForm,
    draggable,
    Layout,
    PageHeader
  },
  name: 'satisfaction-survey',
  page: {
    title: 'Pesquisa de Satisfação',
    meta: [{
      name: 'description',
      content: appConfig.description
    }]
  },
  data () {
    return {
      params: {},
      totalRows: 1,
      currentPage: 1,
      perPage: 20,
      statusLabel: ['Inativa', 'Ativa'],
      idSurvey: null,
      modalForm: false,
      title: 'Pesquisa de Satisfação',
      items: [{
        text: 'Pesquisa de Satisfação'
      },
      {
        text: 'Listagem',
        active: true
      }
      ]
    }
  },
  mounted () {
    this.updateList()
    this.GetSetting()
    this.totalRows = this.pagination.total
    this.currentPage = this.pagination.current_page
  },
  computed: {
    ...mapState('event', ['setting']),
    ...mapGetters('survey', { data: 'SURVEYS' }),
    ...mapState('survey', ['pagination']),
    rows () {
      return this.pagination.total
    },
    surveys: {
      get () {
        return this.data
      },
      set (value) {
        let loader = this.$loading.show()
        this.updateSequence({ itens: value }).then(res => {
          loader.hide()
          successMessage('Sucesso: Ordem atualizada.', 2500)
        })
      }
    }
  },
  methods: {
    ...mapActions('survey', {
      getList: 'getList',
      changeStatus: 'changeStatus',
      deleteAsking: 'deleteAsking',
      updateSequence: 'orderList',
      changeSurvey: 'changeSurvey'
    }),
    ...mapActions('event', {
      GetSetting: 'GetSetting'
    }),
    newSurveyAsking () {
      this.$refs.formSurvey.open('')
      this.modalForm = true
    },
    editSurveyAsking (id) {
      this.$refs.formSurvey.open(id)
      this.modalForm = true
      this.idSurvey = id
    },
    async toggleStatusSurvey () {
      try {
        await this.changeSurvey()
        let message = 'Sucesso: Status atualizado com sucesso.'
        let delay = 2500
        successMessage(message, delay)
        this.GetSetting()
      } catch (err) {
        console.log(err)
        errorMessage('Alerta: ' + err.response.data.message)
      }
    },
    async toggleStatus (id) {
      try {
        await this.changeStatus(id)
        let message = 'Sucesso: Status atualizado com sucesso.'
        let delay = 2500
        successMessage(message, delay)
        this.updateList()
      } catch (err) {
        console.log(err)
        errorMessage('Alerta: ' + err.response.data.message)
      }
    },
    deleteSurveyAsking (id) {
      Swal.fire({
        title: 'Você tem certeza ?',
        text: 'Essa ação não poderá ser revertida',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#f46a6a',
        cancelButtonColor: '#4a4848',
        cancelButtonText: 'CANCELAR',
        confirmButtonText: 'SIM, DESEJO EXCLUIR!'
      }).then(result => {
        if (result.value) {
          try {
            this.deleteAsking(id).then((res) => {
              successMessage('Sucesso: Pergunta excluída com sucesso.', 2500)
              this.updateList()
            }).catch((err) => {
              errorMessage('Alerta: ' + err.message)
            })
          } catch (err) {
            errorMessage('Alerta: ' + err.response.data.message)
          }
        }
      })
    },
    updateList () {
      let loader = this.$loading.show()
      this.getList({ limit: this.perPage, include: 'answers', page: this.currentPage }).then(() => {
        loader.hide()
      })
    }
  },
  watch: {
  }
}
</script>
<style scoped>
.link { cursor: pointer; }
.swal2-popup {
  font-size: 11px !important;
}
.mt-6 { margin-top: 6px !important }
.table-responsive { min-height: 300px;}
.float-sm-end { float: right }
</style>
