<template>
  <div style="margin-left:-15px">
    <div class="col-md-12 text-center" v-show="!room.group_id">
      <h5 class="text-truncate font-size-14 p-4 mt-2">Chat ainda não configurado</h5>
      <i class="bx bx-tired bx-lg"></i>
      <br />
      <br />
      <button type="button" class="btn btn-primary" @click="initChatRoom()">
        Configurar
      </button>
    </div>
    <div v-show="room.group_id">
      <div class="w-100 user-chat">
        <div class="card">
          <div class="p-4 border-bottom">
            <div class="row">
              <div class="col-md-4 col-9">
                <h5 class="font-size-15 mb-1">{{ this.room.name }}</h5>
                <p class="text-muted mb-0">Chat</p>
              </div>
              <div class="col-md-8 col-3">
                <ul class="list-inline user-chat-nav text-right mb-0">
                  <li class="list-inline-item d-none d-sm-inline-block">
                    <b-form-checkbox switch size="md" v-model="autoScroll">Rolagem automática</b-form-checkbox>
                  </li>
                  <li class="list-inline-item">
                    <downloadexcel
                        class            = "btn btn-primary btn-rounded chat-send"
                        :fetch           = "fetchData"
                        :fields          = "json_fields"
                        name             = "chat.xls"
                        :before-generate = "startDownload"
                        :before-finish   = "finishDownload">
                        <i class="bx bxs-cloud-download"></i>
                        Exportar Conversas
                      </downloadexcel>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div class="chat-users">
            <div class="chat-conversation p-3">
              <simplebar
                style="max-height: 470px"
                id="containerElement"
                ref="current"
              >
                <ul class="list-unstyled">
                  <li
                    v-for="(data, i) of messages"
                    :key="i"
                    :class="{ right: `${data.author_id}` === '99999' }"
                  >
                    <div class="conversation-list">
                      <b-dropdown variant="white">
                        <template v-slot:button-content>
                          <i class="bx bx-dots-vertical-rounded"></i>
                        </template>
                        <b-dropdown-item @click="deleteMessage(data.id, i)">Excluir Mensagem</b-dropdown-item>
                        <b-dropdown-item v-if="parseInt(data.author_id) !== 99999" @click="blockUser(data.author_id)">Bloquear Chat</b-dropdown-item>
                      </b-dropdown>
                      <div class="ctext-wrap">
                        <div class="conversation-name">
                          {{ data.author_name }}
                          <span v-if="data.moderator" class="badge badge-pill font-size-12 badge-soft-primary">Moderador</span>
                        </div>
                        <p>{{ data.content }}</p>
                        <p class="chat-time mb-0">
                          <i class="bx bx-time-five align-middle mr-1"></i>
                          {{ $moment(data.createdAt).format('DD/MM HH:mm') }}
                        </p>
                      </div>
                    </div>
                  </li>
                </ul>
              </simplebar>
            </div>
            <div class="p-3 chat-input-section">
              <form @submit.prevent="formSubmit" class="row">
                <div class="col">
                  <div class="position-relative">
                    <input
                      type="text"
                      v-model.lazy="form.message"
                      class="form-control chat-input"
                      placeholder="Digite sua mensagem"
                      :class="{
                        'is-invalid': submitted && $v.form.message.$error,
                      }"
                    />
                    <div
                      v-if="submitted && $v.form.message.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.form.message.required"
                        >O campo mensagem é obrigatório</span
                      >
                    </div>
                    <div class="chat-input-links">
                      <ul class="list-inline mb-0">

                      <b-dropdown variant="white">
                        <template v-slot:button-content>
                          <a href="javascript: void(0);" v-b-tooltip.hover>
                            <i class="mdi mdi-emoticon-happy-outline"></i>
                          </a>
                        </template>
                        <b-dropdown-item>
                          <VEmojiPicker @select="selectEmoji" :i18n="i18n"/>
                        </b-dropdown-item>
                      </b-dropdown>
                      </ul>
                    </div>
                    <div class="box-emojis">
                    </div>
                  </div>
                </div>
                <div class="col-auto">
                  <button
                    type="submit"
                    class="btn btn-primary btn-rounded chat-send w-md"
                  >
                    <span class="d-none d-sm-inline-block mr-2">Send</span>
                    <i class="mdi mdi-send"></i>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import downloadexcel from 'vue-json-excel'
import { mapState, mapGetters, mapActions } from 'vuex'
import simplebar from 'simplebar-vue'
import { VEmojiPicker } from 'v-emoji-picker'
import { required } from 'vuelidate/lib/validators'
import { errorMessage, successMessage } from '@/components/alertsMessages'
import service from './services'
import io from 'socket.io-client'

export default {
  components: {
    downloadexcel,
    simplebar,
    VEmojiPicker
  },
  name: 'chat',
  data () {
    return {
      json_fields: {
        'Data': 'createdAt',
        'Autor': 'author_name',
        'Mensagem': 'content',
        'Moderador': 'moderator'
      },
      submitted: false,
      autoScroll: true,
      showBox: false,
      socket: null,
      content: '',
      i18n: {
        search: 'Pesquisar...',
        categories: {
          Activity: 'Atividades',
          Flags: 'Bandeiras',
          Foods: 'Comida',
          Frequently: 'Frequentes',
          Objects: 'Objetos',
          Nature: 'Natureza',
          Peoples: 'Pessoas',
          Symbols: 'Símbolos',
          Places: 'Locais'
        }
      },
      messages: [],
      form: {
        message: ''
      },
      username: 'Steven Franklin'
    }
  },
  validations: {
    form: {
      message: {
        required
      }
    }
  },
  async beforeMount () {
    let loader = this.$loading.show()
    await this.GetSetting().then((res) => {
      console.log(res)
    }).then(() => {
      setTimeout(() => {
        this.getMessages()
      }, 300)
      loader.hide()
    }).catch((error) => loader.hide(error))
  },
  created () {
    this.socket = io('https://chat.eventfy.live:2617')
  },
  destroyed () {
    this.socket = null
  },
  computed: {
    ...mapGetters('room', { room: 'ROOM' }),
    ...mapState('event', { setting: 'setting' })
  },
  mounted () {
  },
  methods: {
    ...mapActions('room', { saveGroupChat: 'saveGroupId', getRoom: 'GetRoom' }),
    ...mapActions('event', { GetSetting: 'GetSetting' }),
    async fetchData () {
      const response = await service.getMessages({ group_id: this.room.group_id, filter: 'all', limit: 9999999 })
      return response.data.data
    },
    startDownload () {
      let loader = this.$loading.show()
      setTimeout(() => {
        loader.hide()
      }, 200)
    },
    finishDownload () {
      let loader = this.$loading.show()
      setTimeout(() => {
        loader.hide()
      }, 200)
    },
    toogleDialogEmoji () {
      this.showBox = !this.showBox
    },
    selectEmoji (emoji) {
      this.form.message += emoji.data
    },
    handleScroll () {
      if (!this.autoScroll) return
      if (this.$refs.current.$el) {
        setTimeout(() => {
          this.$refs.current.SimpleBar.getScrollElement().scrollTop = this.$refs.current.SimpleBar.getScrollElement().scrollHeight + 1500
        }, 500)
      }
    },
    formSubmit (e) {
      this.submitted = true
      this.$v.$touch()
      if (this.$v.$invalid) {
        return
      }
      this.sendMessage(false)
      this.submitted = false
      this.form = {}
    },
    async blockUser (userId) {
      try {
        await service.blockUserChat(userId).then((res) => {
          let message = 'Sucesso: ' + res.data.message
          let delay = 2500
          successMessage(message, delay)
        })
      } catch (err) {
        console.log(err)
        errorMessage('Alerta: ' + err.message)
      }
    },
    async deleteMessage (id, indice) {
      await this.socket.emit('remove.message', {
        group_id: this.room.group_id,
        group_key: this.room.group_key,
        message_id: id
      })
    },
    async sendMessage (fixed) {
      await this.socket.emit('chat.message', {
        client_id: 2,
        group_id: this.room.group_id,
        group_key: this.room.group_key,
        author_id: 99999,
        author_name: 'Administrador',
        author_avatar: 'https://xcode-eventfy.sfo3.digitaloceanspaces.com/eventfy/users/52f5159e6760ce8f4d46b2ad494d7153745132b4-a.png',
        moderator: true,
        fixed: fixed,
        content: this.form.message
      })
      this.handleScroll()
    },
    async initChatRoom () {
      let chat = {
        name: this.room.slug,
        group_key: this.setting.slug + '#' + this.room.id
      }
      let loader = this.$loading.show()
      await service.saveGroup(chat).then(res => {
        let message = 'Sucesso: Ação registrada com sucesso.'
        let delay = 2500
        this.saveGroupId(res.data)
        successMessage(message, delay)
        loader.hide()
      }).catch((err) => {
        errorMessage('Alerta: ' + err.message)
        loader.hide()
      })
    },
    async getMessages () {
      let loader = this.$loading.show()
      await service.getMessages({ group_id: this.room.group_id }).then(res => {
        this.messages = res.data.data.slice().reverse()
        this.handleScroll()
        loader.hide()
        this.socket.on(`${this.room.group_key}.message.${this.room.group_id}`, (data) => {
          this.messages.push(data)
          this.handleScroll()
        })
        this.socket.on(`${this.room.group_key}.remove.message.${this.room.group_id}`, (data) => {
          let index = this.messages.map(item => item.id).indexOf(data.message_id)
          ~index && this.messages.splice(index, 1)
        })
      }).catch((err) => {
        if (err.response.status === 401) {
          this.getRoom(this.room.id).then((res) => {
            setTimeout(() => {
              window.location.reload()
            }, 200)
            loader.hide()
          }).catch((error) => {
            errorMessage('Alerta: ' + error.message)
            loader.hide()
          })
          return
        }
        errorMessage('Alerta: ' + err.message)
        loader.hide()
      })
    },
    async saveGroupId (data) {
      let group = {
        room_id: this.room.id,
        group_id: data.id,
        group_key: data.group_key
      }
      await this.saveGroupChat(group).then(res => {
      }).catch((err) => {
        errorMessage('Alerta: ' + err.message)
      })
    }
  }
}
</script>
<style scoped>
@font-face {
  font-family: NotomojiColor;
  font-weight: 400;
  src: url(
      https://cdn.glitch.com/61908de1-dd0a-4359-a54b-6cb6d41bb5fd%2FNotoColorEmoji.ttf?1513108808150
    )format("truetype");
}
.box-emojis {
  top: 0px;
  left: 0px;
  position: absolute;
  transform: translateX(212px) translateY(250px) translateZ(0px);
  z-index: 5003;
}
.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #556ee6 !important;
  background-color: #556ee6 !important;
}
</style>
