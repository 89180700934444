<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row mb-2">
              <div class="col-sm-6">
                <div class="search-box mr-2 mb-2 d-inline-block ">
                  <div class="position-relative">
                    <input type="text" class="form-control" v-model="search" placeholder="Digite e pressione 'ENTER'" @keyup.enter="doSearch"/>
                    <i class="bx bx-search-alt search-icon"></i>
                  </div>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="float-sm-end d-flex">
                  <div class="text-sm-right me-2">
                      <button type="button" class="btn  btn-primary btn-rounded mb-2 mr-2" @click="showFilters()">
                        <i class="mdi mdi-filter mr-1"></i> Filtros
                      </button>
                    </div>
                  <div class="text-sm-right">
                    <button type="button" class="btn btn-success btn-rounded mb-2 mr-2" @click="newArchive()">
                      <i class="mdi mdi-plus mr-1"></i> Novo Arquivo
                    </button>
                  </div>
                </div>
              </div>
              <!-- end col-->
            </div>
            <div class="table-responsive">
              <table class="table table-centered table-nowrap table-hover">
                <thead>
                  <tr>
                    <th>Descrição</th>
                    <th>Sala</th>
                    <th>Status</th>
                    <th>Ações</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="list in archives" :key="list.id">
                    <td>{{ list.description }}</td>
                    <td>{{ list.room.data.name }}</td>
                    <td>
                      <span class="badge badge-pill font-size-12" :class="{
                        'badge-soft-success': `${parseInt(list.status)}` === '1',
                        'badge-soft-danger': `${parseInt(list.status)}` === '0',
                      }">{{ statusLabel[list.status] }}</span>
                    </td>
                    <td>
                      <!-- <a href="javascript: void(0);" @click="editArchive(list.id)" class="text-gray" alt="Editar"><i class="bx bx bxs-edit h3 m-0"></i></a>
                      <a href="javascript: void(0);" @click="toggleStatus(list.id)" class="text-dark">
                        <i class="bx h3 m-0" :class="{ 'bx-toggle-right': parseInt(list.status) === 1, 'bx-toggle-left': parseInt(list.status) === 0 }"></i>
                      </a>
                      <a href="javascript: void(0);" @click="deleteParticipant(list.id)" class="text-gray" alt="Excluir"><i class="bx bx bxs-trash h3 m-0"></i></a> -->
                      <b-dropdown class="card-drop" variant="white" right toggle-class="p-0">
                        <template v-slot:button-content>
                          <i class="mdi mdi-dots-horizontal font-size-18"></i>
                        </template>
                        <b-dropdown-item @click="editArchive(list.id)">
                          <i class="fas fa-pencil-alt text-success mr-1"></i> Editar
                        </b-dropdown-item>
                        <b-dropdown-item @click="toggleStatus(list.id)">
                          <i class="fas fa-circle mr-1" :class="{ 'text-danger': parseInt(list.status) === 1, 'text-success': parseInt(list.status) === 0 }"></i> {{ list.status ? 'Inativar' : 'Ativar' }}
                        </b-dropdown-item>
                        <b-dropdown-item @click="deleteArchive(list.id)">
                          <i class="fas fa-trash-alt text-danger mr-1"></i> Excluir
                        </b-dropdown-item>
                      </b-dropdown>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="row">
              <div class="col">
                <div class="dataTables_paginate paging_simple_numbers float-right">
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" @input="updateList"></b-pagination>
                  </ul>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
    <modal-form v-model="modalForm" :idArchive="idArchive" ref="formArchive" @created="updateList"></modal-form>
    <modal-filter ref="modalFilter" @archiveFiltered="getReport"></modal-filter>
  </Layout>
</template>
<script>
import Swal from 'sweetalert2'
import Layout from '@/components/layout/vertical'
import PageHeader from '@/components/page-header'
import ModalForm from './Form'
import modalFilter from '@/components/modals/filters/ArchiveFilter'
import appConfig from '@/app.config'
import { errorMessage, successMessage } from '@/components/alertsMessages'
import { mapActions, mapState } from 'vuex'

export default {
  components: {
    Layout,
    PageHeader,
    ModalForm,
    modalFilter
  },
  name: 'Arquivos',
  page: {
    title: 'Arquivos',
    meta: [{
      name: 'description',
      content: appConfig.description
    }]
  },
  data () {
    return {
      loading: true,
      search: '',
      timeout: null,
      modalForm: false,
      params: {},
      totalRows: 1,
      currentPage: 1,
      idArchive: null,
      perPage: 20,
      statusLabel: ['Inativo', 'Ativo'],
      submitted: false,
      title: 'Arquivos',
      items: [{
        text: 'Arquivos'
      },
      {
        text: 'Listagem',
        active: true
      }
      ]
    }
  },
  mounted () {
    this.updateList()
    this.totalRows = this.pagination.total
    this.currentPage = this.pagination.current_page
    this.loading = false
  },
  computed: {
    ...mapState('archive', ['archives', 'pagination']),
    rows () {
      return this.pagination.total
    }
  },
  methods: {
    ...mapActions('archive', {
      getAll: 'getAll',
      changeStatus: 'changeStatus',
      deleteFile: 'deleteArchive'
    }),
    showFilters () {
      this.$refs.modalFilter.open()
    },
    newArchive () {
      this.$refs.formArchive.open('')
      this.modalForm = true
    },
    editArchive (id) {
      this.$refs.formArchive.open(id)
      this.modalForm = true
      this.idArchive = id
    },
    async toggleStatus (id) {
      try {
        await this.changeStatus(id)
        let message = 'Sucesso: Status atualizado com sucesso.'
        let delay = 2500
        successMessage(message, delay)
        this.updateList()
      } catch (err) {
        console.log(err)
        errorMessage('Alerta: ' + err.response.data.message)
      }
    },
    deleteArchive (id) {
      Swal.fire({
        title: 'Você tem certeza ?',
        text: 'Essa ação não poderá ser revertida',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#f46a6a',
        cancelButtonColor: '#4a4848',
        cancelButtonText: 'CANCELAR',
        confirmButtonText: 'SIM, DESEJO EXCLUIR!'
      }).then(result => {
        if (result.value) {
          try {
            this.deleteFile(id)
            successMessage('Sucesso: Arquivo excluído com sucesso.', 2500)
            this.updateList()
          } catch (err) {
            errorMessage('Alerta: ' + err.response.data.message)
          }
        }
      })
    },
    getReport (filter) {
      let params = {
        include: 'room',
        search: this.search || null,
        limit: this.perPage,
        page: this.currentPage,
        room_id: filter.room_id || null,
        status: filter.status || null
      }
      this.params = params
      let loeader = this.$loading.show()
      this.getAll(this.params).then((res) => {
        // this.participants = res.data
        this.totalRows = this.pagination.total
        this.currentPage = this.pagination.current_page
        loeader.hide()
      }).catch(error => {
        console.log(error)
        loeader.hide()
      })
    },
    doSearch () {
      this.params = { ...this.params, search: this.search }
      this.getReport(this.params)
    },
    updateList () {
      this.getReport(this.params)
    }
  },
  watch: {
    // currentPage () { this.updateList() }
    // total () { this.updateList() },
  }
}
</script>
<style scoped>
.link { cursor: pointer; }
.swal2-popup {
  font-size: 11px !important;
}
.table-responsive { min-height: 300px;}
.float-sm-end { float: right }
</style>
