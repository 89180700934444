<template>
  <div>
    <b-card >
      <b-nav tabs class="nav-tabs-custom">
        <b-nav-item :to="{ name: 'room-transmission' }" exact exact-active-class="active">Transmissão</b-nav-item>
        <b-nav-item :to="{ name: 'room-chat' }" exact exact-active-class="active">Chat</b-nav-item>
        <b-nav-item :to="{ name: 'room-askings' }" exact exact-active-class="active">Perguntas</b-nav-item>
        <b-nav-item :to="{ name: 'room-quiz' }" exact exact-active-class="active">Quiz</b-nav-item>
        <b-nav-item :to="{ name: 'room-poll' }" exact exact-active-class="active">Enquete</b-nav-item>
        <b-nav-item :to="{ name: 'room-word-cloud' }" exact exact-active-class="active">Nuvem de Palavras</b-nav-item>
        <b-nav-item :to="{ name: 'room-call-to-action' }" exact exact-active-class="active">Call to Action</b-nav-item>
      </b-nav>
      <b-card-body class="main-content-default">
        <router-view></router-view>
      </b-card-body>
    </b-card>
  </div>
</template>
<script>
export default {
  name: 'ContentRoom',
  components: {},
  props: {
  },
  data () {
    return {
      chat: false
    }
  },
  created () {}
}
</script>
<style>
.main-content-default {min-height: 307px;}
.nav-tabs {
  border-bottom: 1px solid #ced4da;
}
</style>
