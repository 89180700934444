<template>
  <div>
    <b-modal v-model="modal" :title="title" size="lg" :no-enforce-focus="true" :no-close-on-backdrop="true">
      <loading
        :active.sync="loading"
        color="#556ee6"
        loader="dots"
        :can-cancel="false"
        :is-full-page="false">
      </loading>
      <div class="row">
        <div class="col-lg-12">
          <form @submit.prevent="saveBanner()" autocomplete="off">
            <div class="row">
              <div class="col-sm-4">
                <div class="form-group actions">
                  <label for="name">Imagem</label>
                  <input
                    ref="photo"
                    type="file"
                    name="photo"
                    accept="image/*"
                    @change="setImage"
                  />
                  <div class="link " @click.prevent="showChooser()">
                    <div class="dropzone" v-if="!imgSrc && !banner.url_image">
                      <div class="dropzone-custom-content text-center ">
                        <i class="display-4 text-muted bx bxs-cloud-upload mt-4"></i>
                        <h5 class="text-muted ">Selecionar imagem</h5>
                      </div>
                    </div>
                    <img :src="cropImg || (banner.url_image)" v-if="cropImg || banner.url_image" class="rounded  border-light" style="width:100%;height:100%">
                  </div>
                </div>
              </div>
              <div class="col-sm-8">
                <div class="row">
                  <div class="col-sm-6">
                    <div class="form-group">
                      <label for="type">Tipo</label>
                      <select
                        class="custom-select"
                        name="type"
                        id="type"
                        v-model="banner.type"
                        :class="{
                          'is-invalid': submitted && $v.banner.type.$error,
                        }">
                        <option value="1">Pos. Esquerdo</option>
                        <option value="2">Pos. Direito</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="form-group">
                      <label for="limit_time">Tempo Limite <small>(segundos)</small></label>
                      <input
                        id="limit_time"
                        v-model="banner.limit_time"
                        name="limit_time"
                        type="number"
                        class="form-control text-right"
                        :class="{
                          'is-invalid': submitted && $v.banner.limit_time.$error
                        }"
                      />
                      <div
                        v-if="submitted && !$v.banner.limit_time.required"
                        class="invalid-feedback"
                      >
                        O campo tempo limite é obrigatório.
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-sm-7">
                    <div class="form-group">
                      <label for="action">URL Ação</label>
                      <input
                        id="action"
                        v-model="banner.action"
                        name="action"
                        type="text"
                        class="form-control"
                        :class="{
                          'is-invalid': submitted && $v.banner.action.$error
                        }"
                      />
                      <div
                        v-if="submitted && !$v.banner.action.url"
                        class="invalid-feedback"
                      >
                        O campo url ação precisa ser uma URL válida
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-5">
                    <div class="form-group">
                      <label for="target">Ação</label>
                      <select
                        class="custom-select"
                        name="target"
                        id="target"
                        v-model="banner.target"
                      >
                        <option value=""></option>
                        <option value="_self">Mesma janela (aba)</option>
                        <option value="_blank">Nova janela (aba)</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <template v-slot:modal-footer>
        <b-button variant="secondary" @click="close()">Cancelar</b-button>
        <button type="submit" class="btn btn-primary mr-1" @click="saveBanner()">Salvar</button>
      </template>
    </b-modal>
  </div>
</template>
<script>
// Import component
import Loading from 'vue-loading-overlay'
// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css'
import { mapActions, mapState } from 'vuex'
import { required, url } from 'vuelidate/lib/validators'
import { errorMessage, successMessage } from '@/components/alertsMessages'

export default {
  components: {
    Loading
  },
  props: {
    idVideo: {
      type: Number || String,
      default: null
    }
  },
  data () {
    return {
      title: 'Novo Banner',
      cropImg: '',
      imgSrc: '',
      thumb: require('@/assets/images/image-default.png'),
      loading: true,
      fullPage: true,
      submitted: false,
      banner: {},
      modal: false
    }
  },
  validations: {
    banner: {
      type: { required },
      limit_time: { required },
      action: { url }
    }
  },
  methods: {
    ...mapActions('timelineBanner', {
      getBanner: 'show',
      postData: 'save'
    }),
    loadItem (id) {
      if (id) {
        this.title = 'Editar Banner'
        this.getBanner(id).then((data) => {
          this.banner = this.data
          console.log(this.banner)
        }).then(() => {
          this.loading = false
        })
      }
    },
    open (id = null) {
      this.modal = true
      this.loading = true
      if (id) {
        this.loadItem(id)
        return
      }
      this.loading = false
    },
    async saveBanner () {
      this.submitted = true
      this.$v.banner.$touch()
      if (this.$v.banner.$invalid) {
        window.scroll(0, 0)
        return
      }
      this.loading = true
      const formData = new FormData()
      formData.append('id', this.banner.id || '')
      formData.append('type', this.banner.type)
      formData.append('limit_time', this.banner.limit_time)
      formData.append('action', this.banner.action || '')
      formData.append('target', this.banner.target || '')

      if (this.imgSrc) {
        let ext = this.imgSrc.type.split('/')[1]
        formData.append('photo', this.imgSrc, 'banner.' + ext)
      }
      await this.postData(formData).then(res => {
        let message = 'Sucesso: Dados armazenados com sucesso.'
        let delay = 2500
        successMessage(message, delay)
        this.close()
        this.$emit('created')
      }).catch((err) => {
        errorMessage('Alerta: ' + err.message)
        this.loading = false
      })
    },
    showChooser () {
      if (!this.banner.type) {
        errorMessage('Atenção! Selecione o TIPO de imagem antes!')
        return
      }
      this.$refs.photo.click()
    },
    close () {
      this.modal = false
      this.$refs.photo.value = null
      this.imgSrc = ''
      this.cropImg = ''
      this.banner = {}
      this.$v.$reset()
    },
    setImage (e) {
      const file = e.target.files[0]
      if (file.type.indexOf('image/') === -1) {
        errorMessage('Alerta: Por favor selecione uma imagem.')
        return
      }
      const isLt2M = file.size / 1024 / 1024 < 2
      if (!isLt2M) {
        errorMessage('Alerta: Tamanho máximo 2MB!')
        return
      }
      if (typeof FileReader === 'function') {
        const reader = new FileReader()
        reader.onload = (event) => {
          this.cropImg = event.target.result
        }
        reader.readAsDataURL(file)
        this.imgSrc = file
      } else {
        errorMessage('ERROR: Browser não suporta FileReader')
      }
    }
  },
  computed: {
    ...mapState('timelineBanner', { data: 'timelinebanner' })
  },
  watch: {
    modal () {
      if (!this.modal) {
        this.close()
      }
    }
  }
}
</script>
<style scoped>
input[type="file"] {
  display: none;
}
.vld-overlay.is-full-page {
    z-index: 1051 !important;
}
</style>
