<template>
  <div>
    <b-modal v-model="modal" :title="title" size="lg" :no-enforce-focus="true" :no-close-on-backdrop="true">
      <loading
        :active.sync="loading"
        color="#556ee6"
        loader="dots"
        :can-cancel="false"
        :is-full-page="false">
      </loading>
      <div class="row">
        <div class="col-lg-12">
          <p>Você deve selecionar o evento que deseja conectar</p>
          <form @submit.prevent="saveEvent()" autocomplete="off">
            <div class="row" v-for="evt in events" :key="evt.id">
              <div class="col-sm-6">
                <b-form-checkbox
                class="mb-3"
                v-model="evt.checked"
                plain
                > ({{ evt.id }}) - {{ evt.name }}</b-form-checkbox>
              </div>
            </div>
          </form>
        </div>
      </div>
      <template v-slot:modal-footer>
        <b-button variant="secondary" @click="close()">Cancelar</b-button>
        <button type="submit" class="btn btn-primary mr-1" @click="saveEvent()">Salvar Evento</button>
      </template>
    </b-modal>
  </div>
</template>
<script>
// Import component
import Loading from 'vue-loading-overlay'
// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css'
// import { mapActions, mapState } from 'vuex'
import { errorMessage, successMessage } from '@/components/alertsMessages'
import service from '../services'

export default {
  components: {
    Loading
  },
  props: {
    idVideo: {
      type: Number || String,
      default: null
    }
  },
  data () {
    return {
      title: 'Selecionar evento',
      loading: true,
      fullPage: true,
      submitted: false,
      event: {},
      events: [],
      modal: false
    }
  },
  methods: {
    getEvents () {
      service.getEvents().then(({ data }) => {
        this.events = data.data
      }).then(() => {
        this.loading = false
      }).catch((err) => {
        errorMessage('Atenção: ' + err.message)
        this.loading = false
        this.close()
      })
    },
    open (id = null) {
      this.modal = true
      this.loading = true
      this.getEvents(id)
    },
    async saveEvent () {
      this.submitted = true
      const eventSelected = this.events.filter(o => o.checked === true)
      if (!eventSelected) {
        errorMessage('Alerta: Você deve seleciona um evento!')
        return
      }
      this.loading = true
      await service.saveSymplaEvent({ events: eventSelected }).then(res => {
        let message = 'Sucesso: Dados armazenados com sucesso.'
        let delay = 2500
        successMessage(message, delay)
        this.close()
        this.$emit('createdEvent')
      }).catch((err) => {
        errorMessage('Alerta: ' + err.message)
        this.loading = false
      })
    },
    close () {
      this.modal = false
      this.events = []
    }
  },
  computed: {
  },
  watch: {
    modal () {
      if (!this.modal) {
        this.close()
      }
    }
  }
}
</script>
<style scoped>
input[type="file"] {
  display: none;
}
.vld-overlay.is-full-page {
    z-index: 1051 !important;
}
</style>
