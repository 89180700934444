<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <form @submit.prevent="savePartner()">
          <div class="card">
            <div class="card-body">
              <h4 class="card-title mb-4">Informações do Parceiro</h4>
              <div class="row">
                <div class="col-sm-4">
                  <div class="form-group row mb-4 actions">
                    <label for="name" class="col-form-label col-lg-3">Logo</label>
                    <div class="col-lg-9">
                      <input
                        ref="logo"
                        type="file"
                        name="logo"
                        accept="image/*"
                        @change="setImage"
                      />
                      <div class="link " @click.prevent="showChooser(0)">
                        <div class="dropzone" v-if="!cropImg && !partner.logo">
                          <div class="dropzone-custom-content text-center ">
                            <i class="display-4 text-muted bx bxs-cloud-upload mt-4"></i>
                            <h5 class="text-muted ">Selecionar imagem</h5>
                          </div>
                        </div>
                        <img :src="cropImg || (partner.logo)" v-if="cropImg || partner.logo" class="rounded  border-light" style="width:50%;height:20%">
                      </div>
                    </div>
                  </div>
                  <div class="form-group row mb-4 actions">
                    <label for="name" class="col-form-label col-lg-3">Banner</label>
                    <div class="col-lg-9">
                      <input
                        ref="banner"
                        type="file"
                        name="banner"
                        accept="image/*"
                        @change="setBanner"
                      />
                      <div class="link " @click.prevent="showChooser(1)">
                        <div class="dropzone" v-if="!cropBanner && !partner.banner">
                          <div class="dropzone-custom-content text-center ">
                            <i class="display-4 text-muted bx bxs-cloud-upload mt-4"></i>
                            <h5 class="text-muted ">Selecionar imagem</h5>
                          </div>
                        </div>
                        <img :src="cropBanner || (partner.banner)" v-if="cropBanner || partner.banner" class="rounded thumb border-light">
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-sm-8">
                  <div class="row">
                    <div class="col-sm-8">
                      <div class="form-group">
                        <label for="name">Nome</label>
                        <input
                          id="name"
                          v-model="partner.name"
                          name="name"
                          type="text"
                          class="form-control"
                          :class="{
                            'is-invalid': submitted && $v.partner.name.$error,
                          }"
                        />
                        <div
                          v-if="submitted && !$v.partner.name.required"
                          class="invalid-feedback"
                        >
                          O campo nome é obrigatório
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-4">
                      <div class="form-group">
                        <label for="type_partner">Tipo</label>
                        <select
                          id="type_partner"
                          v-model="partner.type_partner"
                          name="type_partner"
                          class="form-control"
                          :class="{
                            'is-invalid': submitted && $v.partner.type_partner.$error,
                          }"
                        >
                          <option value="1">Patrocinadores</option>
                          <option value="2">Apoiadores</option>
                          <option value="3">Organização</option>
                        </select>
                        <div
                          v-if="submitted && !$v.partner.type_partner.required"
                          class="invalid-feedback"
                        >
                          O campo tipo é obrigatório
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-4">
                      <div class="form-group">
                        <label for="website">Website</label>
                        <input
                          id="website"
                          v-model="partner.website"
                          name="website"
                          type="text"
                          class="form-control"
                          placeholder="URL do Website"
                          :class="{
                            'is-invalid': submitted && $v.partner.website.$error,
                          }"
                        />
                        <div
                          v-if="submitted && !$v.partner.website.url"
                          class="invalid-feedback"
                        >
                        informe uma url válida.
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-4">
                      <div class="form-group">
                        <label for="Facebook">Facebook</label>
                        <input
                          id="facebook"
                          v-model="partner.facebook"
                          name="facebook"
                          type="text"
                          class="form-control"
                          placeholder="URL do facebook"
                          :class="{
                            'is-invalid': submitted && $v.partner.facebook.$error,
                          }"
                        />
                        <div
                          v-if="submitted && !$v.partner.facebook.url"
                          class="invalid-feedback"
                        >
                        informe uma url válida.
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-4">
                      <div class="form-group">
                        <label for="instagram">Instagram</label>
                        <input
                          id="instagram"
                          v-model="partner.instagram"
                          name="instagram"
                          type="text"
                          class="form-control"
                          placeholder="URL do instagram"
                          :class="{
                            'is-invalid': submitted && $v.partner.instagram.$error,
                          }"
                        />
                        <div
                          v-if="submitted && !$v.partner.instagram.url"
                          class="invalid-feedback"
                        >
                        informe uma url válida.
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-4">
                      <div class="form-group">
                        <label for="twitter">Twitter</label>
                        <input
                          id="twitter"
                          v-model="partner.twitter"
                          name="twitter"
                          type="text"
                          class="form-control"
                          placeholder="URL do twitter"
                          :class="{
                            'is-invalid': submitted && $v.partner.twitter.$error,
                          }"
                        />
                        <div
                          v-if="submitted && !$v.partner.twitter.url"
                          class="invalid-feedback"
                        >
                        informe uma url válida.
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-4">
                      <div class="form-group">
                        <label for="youtube">Youtube</label>
                        <input
                          id="youtube"
                          v-model="partner.youtube"
                          name="youtube"
                          type="text"
                          class="form-control"
                          placeholder="URL do youtube"
                          :class="{
                            'is-invalid': submitted && $v.partner.youtube.$error,
                          }"
                        />
                        <div
                          v-if="submitted && !$v.partner.youtube.url"
                          class="invalid-feedback"
                        >
                        informe uma url válida.
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-4">
                      <div class="form-group">
                        <label for="linkedin">linkedin</label>
                        <input
                          id="linkedin"
                          v-model="partner.linkedin"
                          name="linkedin"
                          type="text"
                          class="form-control"
                          placeholder="URL do linkedin"
                          :class="{
                            'is-invalid': submitted && $v.partner.linkedin.$error,
                          }"
                        />
                        <div
                          v-if="submitted && !$v.partner.linkedin.required"
                          class="invalid-feedback"
                        >
                        informe uma url válida.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mt-4">
                <div class="col-sm-12">
                  <div class="form-group">
                    <label for="title">Descrição</label>
                    <ckeditor
                      :editor="editor"
                      v-model="partner.about"
                      :config="editorConfig"
                    >
                    </ckeditor>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card">
            <div class="card-body">
              <div class="float-right">
                <button type="buton" @click="cancelar()" class="btn btn-dark mr-4">
                  Cancelar
                </button>
                <button type="submit" class="btn btn-primary mr-1">
                  Salvar
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </Layout>
</template>
<script>
import 'vue2-datepicker/locale/pt-br'
import CKEditor from '@ckeditor/ckeditor5-vue'
import FullEditor from 'ckeditor5-build-full'
import Layout from '@/components/layout/vertical'
import PageHeader from '@/components/page-header'
import appConfig from '@/app.config'
import { errorMessage, successMessage } from '@/components/alertsMessages'
import { mapActions, mapState } from 'vuex'
import { required, url } from 'vuelidate/lib/validators'

export default {
  components: {
    ckeditor: CKEditor.component,
    Layout,
    PageHeader
  },
  name: 'Parceiro',
  page: {
    title: 'Parceiro',
    meta: [{
      name: 'description',
      content: appConfig.description
    }]
  },
  data () {
    return {
      partner: {
        id: '',
        name: '',
        type_partner: '',
        facebook: '',
        website: '',
        instagram: '',
        twitter: '',
        linkedin: '',
        youtube: '',
        about: '',
        logo: ''
      },
      editor: FullEditor,
      editorConfig: {},
      thumb: require('@/assets/images/image-default.png'),
      imgSrc: '',
      imgBanner: '',
      cropBanner: '',
      cropImg: '',
      submitted: false,
      title: 'Parceiro',
      items: [{
        text: 'Parceiros',
        href: '/partners'
      },
      {
        text: 'Novo Parceiro',
        active: true
      }
      ]
    }
  },
  validations: {
    partner: {
      name: { required },
      type_partner: { required },
      facebook: { url },
      website: { url },
      instagram: { url },
      twitter: { url },
      youtube: { url },
      linkedin: { url }
    }
  },
  mounted () {
    this.loadItem()
  },
  computed: {
    ...mapState('partner', { data: 'partner' })
  },
  methods: {
    ...mapActions('partner', {
      fetch: 'show',
      postData: 'save'
    }),
    cancelar () {
      this.$router.push({ name: 'partners' })
    },
    loadItem () {
      if (this.$route.params.id !== undefined) {
        let loader = this.$loading.show()
        this.items[1].text = 'Editar Parceiro'
        this.fetch(this.$route.params.id)
          .then(() => {
            this.partner = this.data
          }).then(() => {
            loader.hide()
          })
      }
      let loader = this.$loading.show()
      loader.hide()
    },
    setImage (e) {
      const file = e.target.files[0]
      if (file.type.indexOf('image/') === -1) {
        errorMessage('Alerta: Por favor selecione uma imagem.')
        return
      }
      const isLt2M = file.size / 1024 / 1024 < 1
      if (!isLt2M) {
        errorMessage('Alerta: Tamanho máximo 1MB!')
        return
      }
      if (typeof FileReader === 'function') {
        const reader = new FileReader()
        reader.onload = (event) => {
          this.cropImg = event.target.result
        }
        reader.readAsDataURL(file)
        this.imgSrc = file
      } else {
        errorMessage('ERROR: Browser não suporta FileReader')
      }
    },
    setBanner (e) {
      const file = e.target.files[0]
      if (file.type.indexOf('image/') === -1) {
        errorMessage('Alerta: Por favor selecione uma imagem.')
        return
      }
      const isLt2M = file.size / 1024 / 1024 < 2
      if (!isLt2M) {
        errorMessage('Alerta: Tamanho máximo 2MB!')
        return
      }
      if (typeof FileReader === 'function') {
        const reader = new FileReader()
        reader.onload = (event) => {
          this.cropBanner = event.target.result
        }
        reader.readAsDataURL(file)
        this.imgBanner = file
      } else {
        errorMessage('ERROR: Browser não suporta FileReader')
      }
    },
    showChooser (type = 0) {
      if (type) {
        this.$refs.banner.click()
        return
      }
      this.$refs.logo.click()
    },
    async savePartner () {
      this.submitted = true
      this.$v.partner.$touch()
      if (this.$v.partner.$invalid) {
        console.log('teste', this.$v.partner)
        window.scroll(0, 0)
        return
      }
      let loader = this.$loading.show()
      const formData = new FormData()
      formData.append('id', this.partner.id)
      formData.append('name', this.partner.name)
      formData.append('type_partner', this.partner.type_partner)
      formData.append('facebook', this.partner.facebook)
      formData.append('instagram', this.partner.instagram)
      formData.append('twitter', this.partner.twitter)
      formData.append('website', this.partner.website)
      formData.append('linkedin', this.partner.linkedin)
      formData.append('youtube', this.partner.youtube)
      formData.append('about', this.partner.about)

      if (this.imgSrc) {
        let ext = this.imgSrc.type.split('/')[1]
        formData.append('logo', this.imgSrc, 'logo.' + ext)
      }

      if (this.imgBanner) {
        let ext = this.imgBanner.type.split('/')[1]
        formData.append('banner', this.imgBanner, 'banner.' + ext)
      }

      await this.postData(formData).then(res => {
        let message = 'Sucesso: Dados atualizados com sucesso.'
        let delay = 2500
        loader.hide()
        successMessage(message, delay)
        this.cancelar()
      }).catch((err) => {
        errorMessage('Alerta: ' + err.message)
        loader.hide()
      })
    }
  }
}
</script>

<style scoped>
input[type="file"] {
  display: none;
}
.link { cursor: pointer; }
.thumb {
  max-width: 100%;
  max-height: 112px;
  min-height: 112px;
}
.dropzone { max-width: 400px; padding: 0px !important; max-height: 170px; overflow: hidden;}
.border-light {
  padding: 4px;
  display: flex;
  background: #f1f1f1;
  border-radius: 0.25rem;
}
.preview-lg {
  width: 100%;
  height: calc(372px * (9 / 16));
  overflow: hidden;
}
.logo-preview {
  width:200px;
  overflow:hidden;
  height: 112px;
}
.actions {
  margin-top: 1rem;
}
.actions a {
  display: inline-block;
  padding: 5px 15px;
  background: #0062CC;
  color: white;
  text-decoration: none;
  border-radius: 3px;
  margin-right: 1rem;
  margin-bottom: 1rem;
}
.border-dashed {
  border: 2px dashed #ced4da !important;
}
</style>
