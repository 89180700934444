<template>
  <b-modal v-model="modal" :title="title" size="lg" :no-enforce-focus="true" :no-close-on-backdrop="true">
    <loading
      :active.sync="loading"
      color="#556ee6"
      loader="dots"
      :can-cancel="false"
      :is-full-page="false">
    </loading>
    <div class="row">
      <div class="col-lg-12">
        <form @submit.prevent="saveArchive()" autocomplete="off">
          <div class="row">
            <div class="col-sm-12">
              <div class="form-group">
                <label for="description">Descrição</label>
                <input
                  id="description"
                  v-model="archive.description"
                  name="description"
                  type="text"
                  class="form-control"
                  :class="{
                    'is-invalid': submitted && $v.archive.description.$error
                  }"
                />
                <div
                  v-if="submitted && !$v.archive.description.required"
                  class="invalid-feedback"
                >
                  O campo descrição é obrigatório.
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-6">
              <div class="form-group">
                <label for="room_id">Sala</label>
                <room-select
                  :value="roomSelected"
                  @input="setRoom"
                  :class="{
                    'is-invalid': submitted && $v.archive.room_id.$error,
                  }"
                  :classError="$v.archive.room_id.$error ? true : false"
                >
                </room-select>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="form-group">
                <label for="url_download">URL Download</label>
                <input
                  id="url_download"
                  v-model="archive.url_download"
                  name="url_download"
                  type="text"
                  class="form-control"
                  :class="{
                    'is-invalid': submitted && $v.archive.url_download.$error
                  }"
                />
                <div
                  v-if="submitted && !$v.archive.url_download.required"
                  class="invalid-feedback"
                >
                  O campo url é obrigatório.
                </div>
                <div
              v-if="submitted && !$v.archive.url_download.url"
              class="invalid-feedback"
            >
              O campo url download deve ser uma URL válida
            </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
    <template v-slot:modal-footer>
      <b-button variant="secondary" @click="close()">Cancelar</b-button>
      <button type="submit" class="btn btn-primary mr-1" @click="saveArchive()">Salvar</button>
    </template>
  </b-modal>
</template>
<script>
// Import component
import Loading from 'vue-loading-overlay'
// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css'
import { mapActions, mapState } from 'vuex'
import { required, url } from 'vuelidate/lib/validators'
import { errorMessage, successMessage } from '@/components/alertsMessages'
import roomSelect from '@/components/selects/room-select'

export default {
  components: {
    Loading,
    roomSelect
  },
  props: {
    idArchive: {
      type: Number || String,
      default: null
    }
  },
  data () {
    return {
      title: 'Novo Arquivo',
      loading: true,
      roomSelected: {},
      fullPage: true,
      submitted: false,
      states: [],
      archive: {},
      modal: false
    }
  },
  validations: {
    archive: {
      description: { required },
      url_download: { required, url },
      room_id: { required }
    }
  },
  methods: {
    ...mapActions('archive', {
      getArchive: 'show',
      postData: 'save'
    }),
    loadItem (id) {
      if (id) {
        this.title = 'Editar Arquivo'
        this.getArchive(id).then((data) => {
          this.archive = this.data
          this.roomSelected = this.data.room_id
        }).then(() => {
          this.loading = false
        })
      }
    },
    setRoom (room) {
      console.log(room)
      this.archive.room_id = room
    },
    open (id = null) {
      this.modal = true
      this.loading = true
      if (id) {
        this.loadItem(id)
        return
      }
      this.loading = false
    },
    async saveArchive () {
      this.submitted = true
      this.$v.archive.$touch()
      if (this.$v.archive.$invalid) {
        window.scroll(0, 0)
        return
      }
      this.loading = true
      if (typeof (this.selected) === 'object') {
        this.archive.district = this.selected.value
      }
      await this.postData(this.archive).then(res => {
        let message = 'Sucesso: Dados armazenados com sucesso.'
        let delay = 2500
        successMessage(message, delay)
        this.close()
        this.$emit('created')
      }).catch((err) => {
        errorMessage('Alerta: ' + err.message)
        this.loading = false
      })
    },
    close () {
      this.modal = false
      this.archive = {}
      this.$v.$reset()
    }
  },
  computed: {
    ...mapState('archive', { data: 'archive' })
  },
  watch: {
    modal () {
      if (!this.modal) {
        this.close()
      }
    }
  }
}
</script>
<style scoped>
.vld-overlay.is-full-page {
    z-index: 1051 !important;
}
</style>
