<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row mb-2">
              <div class="col-sm-4">
              </div>
              <div class="col-sm-8">
                <div class="float-sm-end d-flex">
                  <div class="text-sm-right">
                    <button type="button" class="btn btn-success btn-rounded mb-2 mr-2" @click="newRaffle()">
                      <i class="mdi mdi-plus mr-1"></i> Novo Sorteio
                    </button>
                  </div>
                </div>
              </div>
              <!-- end col-->
            </div>
            <div class="table-responsive">
              <table class="table table-centered table-nowrap table-hover">
                <thead>
                  <tr>
                    <th width="30%">Nome</th>
                    <th>E-mail</th>
                    <th>Data</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="list in raffles.data" :key="list.id">
                    <td>{{ list.user.name }}</td>
                    <td>{{ list.user.email }}</td>
                    <td>{{ $moment(list.created_at).format('DD/MM/YYYY HH:mm') }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="row">
              <div class="col">
                <div class="dataTables_paginate paging_simple_numbers float-right">
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" @input="updateList"></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <modal-form v-model="modalForm" ref="formRaffle" @created="updateList"></modal-form>
  </Layout>
</template>
<script>
import Layout from '@/components/layout/vertical'
import PageHeader from '@/components/page-header'
import appConfig from '@/app.config'
import ModalForm from './Form'
// import { errorMessage, successMessage } from '@/components/alertsMessages'
import { mapActions, mapState } from 'vuex'

export default {
  components: {
    Layout,
    PageHeader,
    ModalForm
  },
  name: 'raffle',
  page: {
    title: 'Sorteio',
    meta: [{
      name: 'description',
      content: appConfig.description
    }]
  },
  data () {
    return {
      modalForm: false,
      params: {},
      totalRows: 1,
      currentPage: 1,
      perPage: 20,
      statusLabel: ['Inativo', 'Ativo'],
      submitted: false,
      title: 'Sorteio',
      items: [{
        text: 'Sorteio'
      }]
    }
  },
  mounted () {
    this.updateList()
    this.totalRows = this.raffles.total
    this.currentPage = this.raffles.current_page
    this.loading = false
  },
  computed: {
    ...mapState('raffle', ['raffles']),
    rows () {
      return this.raffles.total
    }
  },
  methods: {
    ...mapActions('raffle', {
      getAll: 'getAll'
    }),
    newRaffle () {
      this.$refs.formRaffle.open('')
      this.modalForm = true
    },
    getReport (filter) {
      let params = {
        include: 'user',
        search: this.search || null,
        limit: this.perPage,
        page: this.currentPage
      }
      this.params = params
      let loeader = this.$loading.show()
      this.getAll(this.params).then((res) => {
        // this.participants = res.data
        this.totalRows = this.raffles.total
        this.currentPage = this.raffles.current_page
        loeader.hide()
      }).catch(error => {
        console.log(error)
        loeader.hide()
      })
    },
    doSearch () {
      this.params = { ...this.params, search: this.search }
      this.getReport(this.params)
    },
    updateList () {
      this.getReport(this.params)
    }
  }
}
</script>
<style scoped>
.link { cursor: pointer; }
.swal2-popup {
  font-size: 11px !important;
}
.table-responsive { min-height: 400px;}
.float-sm-end { float: right }
</style>
