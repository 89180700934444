var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Layout',[_c('PageHeader',{attrs:{"title":_vm.title,"items":_vm.items}}),(!_vm.setting.id)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"media"},[_c('div',{staticClass:"media-body overflow-hidden text-center mt-4"},[_c('h5',{staticClass:"text-truncate font-size-15 "},[_vm._v("Olá, "+_vm._s(_vm.user.name)+".")]),_c('p',{staticClass:"text-muted"},[_vm._v("Indentificamos que a sua plataforma ainda não está configurada.")]),_c('router-link',{attrs:{"to":{ name: 'event' }}},[_c('b-button',{staticClass:"mt-4 mb-4",attrs:{"variant":"primary","size":"lg"}},[_c('i',{staticClass:"bx bx-cog font-size-16 align-middle mr-2"}),_vm._v(" CONFIGURAR AGORA! ")])],1)],1)])])])])]):_vm._e(),(_vm.setting.id)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.saveSetting()}}},[_c('div',{staticClass:"form-group row mb-4"},[_c('label',{staticClass:"col-form-label col-lg-3",attrs:{"for":"session_register"}},[_vm._v("Liberar Cadastro")]),_c('div',{staticClass:"col-lg-9"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.setting.session_register),expression:"setting.session_register"}],staticClass:"custom-select",class:{
                    'is-invalid': _vm.submitted && _vm.$v.setting.session_register.$error,
                  },attrs:{"name":"session_register","id":"session_register"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.setting, "session_register", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":"1"}},[_vm._v("Autorizado")]),_c('option',{attrs:{"value":"0"}},[_vm._v("Suspenso")])])])]),_c('div',{staticClass:"form-group row mb-4"},[_c('label',{staticClass:"col-form-label col-lg-3",attrs:{"for":"send_email"}},[_vm._v("Confirmação de Cadastro")]),_c('div',{staticClass:"col-lg-9"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.setting.send_email),expression:"setting.send_email"}],staticClass:"custom-select",class:{
                    'is-invalid': _vm.submitted && _vm.$v.setting.send_email.$error,
                  },attrs:{"name":"send_email","id":"send_email"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.setting, "send_email", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":"1"}},[_vm._v("Enviar e-mail de confirmação")]),_c('option',{attrs:{"value":"0"}},[_vm._v("Não enviar e-mail")])])])]),_c('div',{staticClass:"form-group row mb-4"},[_c('label',{staticClass:"col-form-label col-lg-3",attrs:{"for":"name"}},[_vm._v("Liberar Autenticação")]),_c('div',{staticClass:"col-lg-9"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.setting.session_login),expression:"setting.session_login"}],staticClass:"custom-select",class:{
                    'is-invalid': _vm.submitted && _vm.$v.setting.session_login.$error,
                  },attrs:{"name":"session_login","id":"session_login"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.setting, "session_login", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":"1"}},[_vm._v("Autorizado")]),_c('option',{attrs:{"value":"0"}},[_vm._v("Suspenso")])])])]),(parseInt(_vm.setting.session_login) === 0)?_c('div',{staticClass:"form-group row mb-4"},[_c('label',{staticClass:"col-form-label col-lg-3",attrs:{"for":"message_login"}},[_vm._v("Mensagem")]),_c('div',{staticClass:"col-lg-9"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.setting.message_login),expression:"setting.message_login"}],staticClass:"form-control",attrs:{"id":"message_login","name":"message_login","type":"text","placeholder":"Informe uma mensagem para o login suspenso."},domProps:{"value":(_vm.setting.message_login)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.setting, "message_login", $event.target.value)}}})])]):_vm._e(),_c('div',{staticClass:"form-group row mb-4"},[_c('label',{staticClass:"col-form-label col-lg-3",attrs:{"for":"access_type"}},[_vm._v("Tipo de Autenticação")]),_c('div',{staticClass:"col-lg-9"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.setting.access_type),expression:"setting.access_type"}],staticClass:"custom-select",class:{
                    'is-invalid': _vm.submitted && _vm.$v.setting.access_type.$error,
                  },attrs:{"name":"access_type","id":"access_type"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.setting, "access_type", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":"1"}},[_vm._v("Email e senha")]),_c('option',{attrs:{"value":"2"}},[_vm._v("E-mail")])])])]),_c('div',{staticClass:"form-group row mb-4"},[_c('label',{staticClass:"col-form-label col-lg-3",attrs:{"for":"name"}},[_vm._v("Banner Login")]),_c('div',{staticClass:"col-lg-9 actions"},[_c('input',{ref:"bannerlogin",attrs:{"type":"file","name":"bannerlogin","accept":"image/*"},on:{"change":_vm.setImageBL}}),(_vm.setting.banner_login)?_c('div',{staticClass:"popup-gallery border-dashed link",staticStyle:{"max-width":"132px"}},[_c('img',{attrs:{"src":_vm.setting.banner_login,"width":"120"},on:{"click":function($event){_vm.singleImage = true}}}),_c('vue-easy-lightbox',{attrs:{"visible":_vm.singleImage,"imgs":_vm.setting.banner_login},on:{"hide":function($event){_vm.singleImage = false}}})],1):_vm._e()])]),_c('div',{staticClass:"mt-5"},[_c('button',{staticClass:"btn btn-primary mr-1",attrs:{"type":"submit"}},[_vm._v(" Salvar ")])])])])])])]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }