var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Layout',[_c('PageHeader',{attrs:{"title":_vm.title,"items":_vm.items}}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12"},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.saveRoom()}}},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('h4',{staticClass:"card-title mb-4"},[_vm._v("Informações da Sala")]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-4"},[_c('input',{ref:"thumb",attrs:{"type":"file","name":"thumb","accept":"image/*"},on:{"change":_vm.setImage}}),_c('div',{staticClass:"link",on:{"click":function($event){$event.preventDefault();return _vm.showChooser($event)}}},[(!_vm.cropImg && !_vm.room.thumb_image)?_c('div',{staticClass:"dropzone"},[_c('div',{staticClass:"dropzone-custom-content text-center "},[_c('i',{staticClass:"display-4 text-muted bx bxs-cloud-upload mt-4"}),_c('h5',{staticClass:"text-muted "},[_vm._v("Selecionar imagem")])])]):_vm._e(),(_vm.cropImg || _vm.room.thumb_image)?_c('img',{staticClass:"rounded thumb border-light",attrs:{"src":_vm.cropImg || (_vm.room.thumb_image)}}):_vm._e()])]),_c('div',{staticClass:"col-sm-8"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-12"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"name"}},[_vm._v("Nome")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.room.name),expression:"room.name"}],staticClass:"form-control",class:{
                          'is-invalid': _vm.submitted && _vm.$v.room.name.$error,
                        },attrs:{"id":"name","name":"name","type":"text"},domProps:{"value":(_vm.room.name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.room, "name", $event.target.value)}}}),(_vm.submitted && !_vm.$v.room.name.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" O campo nome é obrigatório ")]):_vm._e()])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-4"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"title"}},[_vm._v("Tipo")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.room.allow_users),expression:"room.allow_users"}],staticClass:"custom-select",class:{
                          'is-invalid': _vm.submitted && _vm.$v.room.allow_users.$error,
                        },attrs:{"name":"allow_users","id":"allow_users"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.room, "allow_users", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":"0"}},[_vm._v("Todos usuários")]),_c('option',{attrs:{"value":"1"}},[_vm._v("Restrita")])]),(_vm.submitted && !_vm.$v.room.allow_users.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" O campo tipo é obrigatório ")]):_vm._e()])]),_c('div',{staticClass:"col-sm-4"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"title"}},[_vm._v("Abertura")]),_c('date-picker',{class:{
                          'is-invalid': _vm.submitted && _vm.$v.room.start_date.$error,
                        },attrs:{"type":"datetime","first-day-of-week":1,"lang":"pt-br","format":"DD/MM/YYYY HH:mm"},model:{value:(_vm.room.start_date),callback:function ($$v) {_vm.$set(_vm.room, "start_date", $$v)},expression:"room.start_date"}}),(_vm.submitted && !_vm.$v.room.start_date.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" O campo Abertura é obrigatório ")]):_vm._e()],1)]),_c('div',{staticClass:"col-sm-4"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"title"}},[_vm._v("Encerramento")]),_c('date-picker',{class:{
                          'is-invalid': _vm.submitted && _vm.$v.room.end_date.$error,
                        },attrs:{"type":"datetime","first-day-of-week":1,"lang":"pt-br","format":"DD/MM/YYYY HH:mm"},model:{value:(_vm.room.end_date),callback:function ($$v) {_vm.$set(_vm.room, "end_date", $$v)},expression:"room.end_date"}}),(_vm.submitted && !_vm.$v.room.end_date.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" O campo Encerramento é obrigatório ")]):_vm._e()],1)])])])]),_c('div',{staticClass:"row mt-4"},[_c('div',{staticClass:"col-sm-12"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"title"}},[_vm._v("Descrição")]),_c('ckeditor',{attrs:{"editor":_vm.editor,"config":_vm.editorConfig},model:{value:(_vm.room.description),callback:function ($$v) {_vm.$set(_vm.room, "description", $$v)},expression:"room.description"}})],1)])])])]),_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"float-right"},[_c('button',{staticClass:"btn btn-dark mr-4",attrs:{"type":"buton"},on:{"click":function($event){return _vm.cancelar()}}},[_vm._v(" Cancelar ")]),_c('button',{staticClass:"btn btn-primary mr-1",attrs:{"type":"submit"}},[_vm._v(" Salvar ")])])])])])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }