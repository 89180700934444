<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row" v-if="!setting.id">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="media">
              <div class="media-body overflow-hidden text-center mt-4">
                <h5 class="text-truncate font-size-15 ">Olá, {{ user.name }}.</h5>
                <p class="text-muted">Indentificamos que a sua plataforma ainda não está configurada.</p>

                <b-button variant="primary" size="lg" class="mt-4 mb-4" @click="initSetting()">
                  <i class="bx bx-cog font-size-16 align-middle mr-2"></i>
                  CONFIGURAR AGORA!
                </b-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row" v-if="setting.id || configEvent">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <form @submit.prevent="saveSetting()">
              <div class="form-group row mb-4">
                <label for="name" class="col-form-label col-lg-2">Nome do Evento</label>
                <div class="col-lg-10">
                  <input
                    id="name"
                    name="name"
                    v-model="setting.name"
                    type="text"
                    class="form-control"
                    :class="{
                      'is-invalid': submitted && $v.setting.name.$error,
                    }"
                    placeholder="Inform o nome do evento"
                  />
                </div>
              </div>
              <div class="form-group row mb-4">
                <label for="description" class="col-form-label col-lg-2">Descrição do Evento</label>
                <div class="col-lg-10">
                  <ckeditor
                    :editor="editor"
                    v-model="setting.description"
                    :config="editorConfig"
                    :class="{
                      'is-invalid': submitted && $v.setting.description.$error,
                    }"
                  >
                  </ckeditor>
                </div>
              </div>
              <div class="form-group row mb-4">
                <label for="name" class="col-form-label col-lg-2">E-mail de Contato</label>
                <div class="col-lg-10">
                  <input
                    id="name"
                    name="name"
                    v-model="setting.contact_email"
                    type="text"
                    class="form-control"
                    :class="{
                      'is-invalid': submitted && $v.setting.contact_email.$error,
                    }"
                    placeholder="Informe um e-mail para contato"
                  />
                </div>
              </div>
              <div class="form-group row mb-4 actions">
                <label for="name" class="col-form-label col-lg-2">Logotipo</label>
                <div class="col-lg-10">
                  <input
                    ref="logo"
                    type="file"
                    name="logo"
                    accept="image/*"
                    @change="setImage"
                  />
                </div>
              </div>
              <div class="form-group row mb-4">
                <label for="name" class="col-form-label col-lg-2">Banner Home</label>
                <div class="col-lg-10 actions">
                  <input
                    ref="bannerhome"
                    type="file"
                    name="bannerhome"
                    accept="image/*"
                    @change="setImageBH"
                  />
                  <div class="popup-gallery border-dashed link" style="max-width:132px" v-if="setting.banner">
                    <img :src="cropBannerHome || setting.banner" width="120" @click="singleImageBH = true" />
                    <vue-easy-lightbox
                      :visible="singleImageBH"
                      :imgs="setting.banner"
                      @hide="singleImageBH = false"
                    ></vue-easy-lightbox>
                  </div>
                </div>
              </div>
              <div class="mt-5">
                <button type="submit" class="btn btn-primary mr-1">
                  Salvar
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
<script>
import VueEasyLightbox from 'vue-easy-lightbox'
import CKEditor from '@ckeditor/ckeditor5-vue'
import FullEditor from 'ckeditor5-build-full'
import Layout from '@/components/layout/vertical'
import PageHeader from '@/components/page-header'
import appConfig from '@/app.config'
import { errorMessage, successMessage } from '@/components/alertsMessages'
import { mapActions, mapState } from 'vuex'

export default {
  components: {
    ckeditor: CKEditor.component,
    VueEasyLightbox,
    Layout,
    PageHeader
  },
  name: 'Evento',
  page: {
    title: 'Configurações Gerais',
    meta: [{
      name: 'description',
      content: appConfig.description
    }]
  },
  data () {
    return {
      editor: FullEditor,
      editorConfig: {},
      loading: true,
      singleImageBH: false,
      imgSrc: '',
      imgSrcBannerHome: '',
      cropImg: '',
      cropBannerHome: '',
      cropLogo: false,
      resetLogo: false,
      submitted: false,
      configEvent: false,
      title: 'Configurações Gerais',
      items: [{
        text: 'Meu Evento',
        href: '/'
      },
      {
        text: 'Configurações Gerais',
        active: true
      }
      ]
    }
  },
  mounted () {
    let loader = this.$loading.show()
    this.GetSetting().then(() => {
      loader.hide()
    })
  },
  computed: {
    ...mapState('auth', ['user']),
    ...mapState('event', ['setting'])
  },
  methods: {
    ...mapActions('event', {
      GetSetting: 'GetSetting',
      postSetting: 'SaveSetting'
    }),
    initSetting () {
      this.configEvent = true
    },
    setImage (e) {
      const file = e.target.files[0]
      if (file.type.indexOf('image/') === -1) {
        errorMessage('Alerta: Por favor selecione uma imagem.')
        return
      }
      const isLt2M = file.size / 1024 / 1024 < 2
      if (!isLt2M) {
        errorMessage('Alerta: Tamanho máximo 2MB!')
        return
      }
      if (typeof FileReader === 'function') {
        this.cropImg = file
      } else {
        errorMessage('ERROR: Browser não suporta FileReader')
      }
    },
    setImageBH (e) {
      console.log(e)
      const file = e.target.files[0]
      if (file.type.indexOf('image/') === -1) {
        errorMessage('Alerta: Por favor selecione uma imagem.')
        return
      }
      const isLt2M = file.size / 1024 / 1024 < 3
      if (!isLt2M) {
        errorMessage('Alerta: Tamanho máximo 3MB!')
        return
      }
      if (typeof FileReader === 'function') {
        this.cropBannerHome = file
      } else {
        errorMessage('ERROR: Browser não suporta FileReader')
      }
    },
    async saveSetting () {
      let loader = this.$loading.show()
      try {
        const formData = new FormData()
        formData.append('id', this.setting.id)
        formData.append('name', this.setting.name)
        formData.append('description', this.setting.description)
        formData.append('contact_email', this.setting.contact_email)
        if (this.cropImg) {
          let ext = this.cropImg.type.split('/')[1]
          formData.append('logo', this.cropImg, 'logo.' + ext)
        }
        if (this.cropBannerHome) {
          let ext = this.cropBannerHome.type.split('/')[1]
          formData.append('banner', this.cropBannerHome, 'bannerhome.' + ext)
        }
        await this.postSetting(formData).then(() => {
          let message = 'Sucesso: Dados atualizados com sucesso.'
          let delay = 2500
          successMessage(message, delay)
          loader.hide()
        })
      } catch (err) {
        console.log(err)
        loader.hide()
        errorMessage('Alerta: ' + err.response.data.message)
      }
    }
  }
}
</script>

<style scoped>
input[type="file"] {
  display: block;
}
.link { cursor: pointer; }
.preview-lg {
  width: 100%;
  height: calc(372px * (9 / 16));
  overflow: hidden;
}
.logo-preview {
  width:200px;
  overflow:hidden;
  height: 65px;
}
.actions {
  margin-top: 1rem;
}
.actions a {
  display: inline-block;
  padding: 5px 15px;
  background: #0062CC;
  color: white;
  text-decoration: none;
  border-radius: 3px;
  margin-right: 1rem;
  margin-bottom: 1rem;
}
.bg-black { background-color: #afafaf; opacity: 1; }
.border-dashed {
  border: 2px dashed #ced4da !important;
  padding: 4px;
}
</style>
