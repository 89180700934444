<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row" v-if="!setting.id">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="media">
              <div class="media-body overflow-hidden text-center mt-4">
                <h5 class="text-truncate font-size-15 ">Olá, {{ user.name }}.</h5>
                <p class="text-muted">Indentificamos que a sua plataforma ainda não está configurada.</p>
                <router-link :to="{ name: 'event' }">
                  <b-button variant="primary" size="lg" class="mt-4 mb-4" >
                    <i class="bx bx-cog font-size-16 align-middle mr-2"></i> CONFIGURAR AGORA!
                  </b-button>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row" v-if="setting.id">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title mb-4">Customizar Emojis</h4>
            <form @submit.prevent="updateImageEmoji()">
              <div class="form-group row">
                <label for="name" class="col-form-label col-lg-1">Alterar</label>
                <div class="col-lg-2">
                  <select
                    class="custom-select"
                    name="emoji"
                    id="emoji"
                    v-model="emojis.type"
                    :class="{
                      'is-invalid': submitted && $v.emojis.type.$error
                    }">
                    <option value="likes">Gostei</option>
                    <option value="love">Amei</option>
                    <option value="wow">Wow</option>
                    <option value="hands">Palmas</option>
                    <option value="rocket">Decolou</option>
                    <option value="incredible">Incrível</option>
                  </select>
                  <div
                      v-if="submitted && !$v.emojis.type.required"
                      class="invalid-feedback"
                    >
                    Qual emoji atualizar ?
                  </div>
                </div>
                <div class="mt-4 mt-md-0 col-lg-4">
                  <input type="file" class="form-control form-control-file" @change="setImage" placeholder="Selecione imagem do novo emoji">
                </div>
                <div class="rol-lg-1">
                  <button type="submit" class="btn btn-primary mr-1">
                    Atualizar
                  </button>
                </div>
              </div>
            </form>
            <div class="form-group row mb-4 mt-4">
              <div class="col-lg-2 text-center">
                <span class="text-muted font-size-11">Gostei</span><br />
                <img :src="setting.custom.data.likes" class="emojis link" @click.prevent="showUpload()"/>
              </div>

              <div class="col-lg-2 text-center">
                <span class="text-muted font-size-11">Amei</span><br />
                <img :src="setting.custom.data.love" class="emojis"/>
              </div>

              <div class="col-lg-2 text-center">
                <span class="text-muted font-size-11">Decolou</span><br />
                <img :src="setting.custom.data.rocket" class="emojis"/>
              </div>

              <div class="col-lg-2 text-center">
                <span class="text-muted font-size-11">Wow</span><br />
                <img :src="setting.custom.data.wow" class="emojis"/>
              </div>

              <div class="col-lg-2 text-center">
                <span class="text-muted font-size-11">Incrível</span><br />
                <img :src="setting.custom.data.incredible" class="emojis"/>
              </div>

              <div class="col-lg-2 text-center">
                <span class="text-muted font-size-11">Parabéns</span><br />
                <img :src="setting.custom.data.hands" class="emojis"/>
              </div>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <h4 class="card-title mb-4">Customizar Cores - Plataforma</h4>
            <form @submit.prevent="updateColors()">
              <div class="row">
                <div class="col-sm-2">
                  <div class="form-group">
                    <label for="primary_color">Cor Principal</label>
                    <b-form-input
                      id="primary_color"
                      v-model="setting.primary_color"
                      type="color"
                      :class="{
                        'is-invalid': submitted && $v.setting.primary_color.$error,
                      }"
                    ></b-form-input>
                    <div
                      v-if="submitted && !$v.setting.primary_color.required"
                      class="invalid-feedback"
                    >
                      O campo cor principal é obrigatório.
                    </div>
                  </div>
                </div>

                <div class="col-sm-2">
                  <div class="form-group">
                    <label for="secondary_color">Cor Secundária</label>
                    <b-form-input
                      id="secondary_color"
                      v-model="setting.secondary_color"
                      type="color"
                      :class="{
                        'is-invalid': submitted && $v.setting.secondary_color.$error,
                      }"
                    ></b-form-input>
                    <div
                      v-if="submitted && !$v.setting.secondary_color.required"
                      class="invalid-feedback"
                    >
                      O campo cor Secundária é obrigatória.
                    </div>
                  </div>
                </div>
                <div class="col-sm-2">
                  <button type="submit" class="btn btn-primary mr-1 mt-4">
                    Atualizar
                  </button>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-12">
                  <label class="mb-1 mt-3 fw-medium">Atenção</label>
                  <p class="text-muted font-13">Cor principal será vinculada a botões, efeitos "hover,active" no layout.</p>
                  <p class="text-muted font-13">Cor Secundária será vinculada a background dos headers e títulos das páginas.</p>
                </div>
              </div>
            </form>
          </div>
        </div>

        <div class="card">
          <div class="card-body">
            <h4 class="card-title mb-4">Customizar Cores - APP</h4>
            <form @submit.prevent="updateColors()">
              <div class="row">
                <div class="col-sm-2">
                  <div class="form-group">
                    <label for="bg_color">Cor Background</label>
                    <b-form-input
                      id="bg_color"
                      v-model="setting.bg_color"
                      type="color"
                      :class="{
                        'is-invalid': submitted && $v.setting.bg_color.$error,
                      }"
                    ></b-form-input>
                    <div
                      v-if="submitted && !$v.setting.bg_color.required"
                      class="invalid-feedback"
                    >
                      O campo cor background é obrigatório.
                    </div>
                  </div>
                </div>

                <div class="col-sm-2">
                  <div class="form-group">
                    <label for="font_color">Cor Fontes</label>
                    <b-form-input
                      id="font_color"
                      v-model="setting.font_color"
                      type="color"
                      :class="{
                        'is-invalid': submitted && $v.setting.font_color.$error,
                      }"
                    ></b-form-input>
                    <div
                      v-if="submitted && !$v.setting.font_color.required"
                      class="invalid-feedback"
                    >
                      O campo cor fonte é obrigatória.
                    </div>
                  </div>
                </div>
                <div class="col-sm-2">
                  <div class="form-group">
                    <label for="buttons_color">Cor Botões</label>
                    <b-form-input
                      id="buttons_color"
                      v-model="setting.buttons_color"
                      type="color"
                      :class="{
                        'is-invalid': submitted && $v.setting.buttons_color.$error,
                      }"
                    ></b-form-input>
                    <div
                      v-if="submitted && !$v.setting.buttons_color.required"
                      class="invalid-feedback"
                    >
                      O campo cor botão é obrigatória.
                    </div>
                  </div>
                </div>
                <div class="col-sm-2">
                  <button type="submit" class="btn btn-primary mr-1 mt-4">
                    Atualizar
                  </button>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-12">
                  <label class="mb-1 mt-3 fw-medium">Atenção</label>
                  <p class="text-muted font-13">Cor principal será vinculada a botões, efeitos "hover,active" no layout.</p>
                  <p class="text-muted font-13">Cor Secundária será vinculada a background dos headers e títulos das páginas.</p>
                </div>
              </div>
            </form>
          </div>
        </div>

      </div>
    </div>
  </Layout>
</template>
<script>
import Layout from '@/components/layout/vertical'
import PageHeader from '@/components/page-header'
import appConfig from '@/app.config'
import { errorMessage, successMessage } from '@/components/alertsMessages'
import { required } from 'vuelidate/lib/validators'
import { mapActions, mapState } from 'vuex'

export default {
  components: {
    Layout,
    PageHeader
  },
  name: 'customization',
  page: {
    title: 'Customizações',
    meta: [{
      name: 'description',
      content: appConfig.description
    }]
  },
  data () {
    return {
      loading: true,
      newemoji: '',
      emojis: { type: '' },
      submitted: false,
      title: 'Customizações',
      items: [{
        text: 'Meu Evento',
        href: '/'
      },
      {
        text: 'Customizações',
        active: true
      }
      ]
    }
  },
  validations: {
    emojis: {
      type: { required }
    },
    setting: {
      primary_color: { required },
      secondary_color: { required }
    }
  },
  mounted () {
    let loader = this.$loading.show()
    this.GetSetting().then(() => {
      loader.hide()
    })
  },
  computed: {
    ...mapState('auth', ['user']),
    ...mapState('event', ['setting'])
  },
  methods: {
    ...mapActions('event', {
      GetSetting: 'GetSetting',
      postSetting: 'SaveSetting',
      postEmoji: 'changeEmoji',
      postColors: 'changeColors'
    }),
    setImage (e) {
      const file = e.target.files[0]
      if (file.type.indexOf('image/') === -1) {
        errorMessage('Alerta: Por favor selecione uma imagem.')
        return
      }
      const isLt2M = file.size / 1024 / 1024 < 1
      if (!isLt2M) {
        errorMessage('Alerta: Tamanho máximo 1MB!')
        return
      }
      if (typeof FileReader === 'function') {
        this.newemoji = file
      } else {
        errorMessage('ERROR: Browser não suporta FileReader')
      }
    },
    async updateImageEmoji () {
      this.submitted = true
      this.$v.emojis.$touch()
      if (this.$v.emojis.$invalid) {
        window.scroll(0, 0)
        return
      }
      let loader = this.$loading.show()

      const formData = new FormData()
      let ext = this.newemoji.type.split('/')[1]
      formData.append('id', this.setting.custom.data.id)
      formData.append('setting_id', this.setting.id)
      formData.append('type', this.emojis.type)
      formData.append('image', this.newemoji, 'newemoji.' + ext)
      await this.postEmoji(formData).then(res => {
        let message = 'Sucesso: Ação registrada com sucesso.'
        let delay = 2500
        successMessage(message, delay)
        this.GetSetting().then(() => {
          this.emojis = {}
          this.$v.$reset()
          loader.hide()
        })
      }).catch((err) => {
        errorMessage('Alerta: ' + err.message)
        loader.hide()
      })
    },
    async updateColors () {
      this.submitted = true
      this.$v.setting.$touch()

      if (this.$v.setting.$invalid) {
        window.scroll(0, 0)
        return
      }

      let loader = this.$loading.show()

      let colors = {
        id: this.setting.id,
        primary_color: this.setting.primary_color,
        secondary_color: this.setting.secondary_color,
        bg_color: this.setting.bg_color,
        font_color: this.setting.font_color,
        buttons_color: this.setting.buttons_color
      }

      await this.postColors(colors).then(res => {
        let message = 'Sucesso: Ação registrada com sucesso.'
        let delay = 2500
        successMessage(message, delay)
        this.GetSetting().then(() => {
          this.$v.$reset()
          loader.hide()
        })
      }).catch((err) => {
        errorMessage('Alerta: ' + err.message)
        loader.hide()
      })
    }
  }
}
</script>

<style scoped>
input[type="file"] {
  display: block !important;
}
.emojis { max-width: 64px; overflow: hidden;}
.link { cursor: pointer; }
.preview-lg {
  width: 100%;
  height: calc(372px * (9 / 16));
  overflow: hidden;
}
.logo-preview {
  width:200px;
  overflow:hidden;
  height: 65px;
}
.actions {
  margin-top: 1rem;
}
.actions a {
  display: inline-block;
  padding: 5px 15px;
  background: #0062CC;
  color: white;
  text-decoration: none;
  border-radius: 3px;
  margin-right: 1rem;
  margin-bottom: 1rem;
}
.bg-black { background-color: #afafaf; opacity: 1; }
.border-dashed {
  border: 2px dashed #ced4da !important;
  padding: 4px;
}
</style>
